import React, { useContext, useEffect, useState } from 'react'
import { Platform, Pressable, Alert, Linking } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import * as DocumentPicker from 'expo-document-picker'
import * as Location from 'expo-location'
import UploadWeb from './UploadWeb'
import mime from 'mime'
import { useActionSheet } from '@expo/react-native-action-sheet'
import * as MediaLibrary from 'expo-media-library'
import { VALID_DOCUMENTS, VALID_FORMATS_FILES } from '@utils/constants'
import ModalLoading from './ModalLoading'
import LoadingAnimation from '@assets/animations/lottie/loading'
import { LanguageContext } from '@contextState/language'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import useModal from '@hooks/useModal'

function UploadInput({
  children,
  style,
  handleFile,
  accept,
  showCameraRollOption,
  showLocation,
  cancelOnPress,
}) {
  const [loading, setLoading] = useState(false)
  const { showActionSheetWithOptions } = useActionSheet()
  const { t } = useContext(LanguageContext)

  const { isModalVisible, toggleModal, closeModal } = useModal()

  useEffect(() => {
    getPermissionsAsync()
  }, [])

  // MOBILE PERMISSIONS
  async function getPermissionsAsync() {
    if (Platform.OS !== 'web') {
      if (showCameraRollOption) {
        await ImagePicker.requestMediaLibraryPermissionsAsync()
        let { status: existingStatus } = ImagePicker.getCameraPermissionsAsync()

        if (existingStatus !== 'granted') {
          const status = await ImagePicker.requestCameraPermissionsAsync()
          existingStatus = status.status
        }

        const status2 = ImagePicker.getCameraPermissionsAsync()
        existingStatus = status2.status

        if (existingStatus !== 'granted') {
          Alert.alert(
            t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_1,
            t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_2,
            [
              {
                text: t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_3,
                onPress: () => cancelOnPress(),
              },
              {
                text: t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_4,
                onPress: () => Linking.openSettings(),
              },
            ],
            { cancelable: false }
          )
          return
        }
      }

      if (showLocation) {
        await Location.requestForegroundPermissionsAsync()
      }
    }
  }

  async function _getExif(result) {
    if (result?.uri) {
      const asset = await MediaLibrary.createAssetAsync(result?.uri)
      const exif = await MediaLibrary.getAssetInfoAsync(asset)
      return exif
    }

    return {}
  }

  async function _pickDocument(type = 'document') {
    try {
      let result = {}
      let location = {}

      if (type === 'document') {
        result = await DocumentPicker.getDocumentAsync({
          type: '*/*',
          copyToCacheDirectory: true,
        })

        const getType = mime.getType(result.uri)

        const typeMach =
          getType.match(
            accept === '.kmz' ? VALID_FORMATS_FILES : VALID_DOCUMENTS
          ) != null

        if (!typeMach) {
          toggleModal()
          return
        }

        if (showLocation && mime.getType(result.uri).indexOf('image') > -1) {
          const exif = await _getExif(result)

          if (exif?.location) {
            location = exif.location
          }
        }
      } else if (type === 'gallery') {
        setLoading(true)
        result = await ImagePicker.launchImageLibraryAsync({
          quality: 0.5,
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
        })

        if (showLocation) {
          const exif = await _getExif(result)
          if (exif?.location) {
            location = exif.location
          }
        }
      } else if (type === 'camera') {
        try {
          setLoading(true)

          result = await ImagePicker.launchCameraAsync({
            quality: 0.5,
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            exif: true,
          })

          if (showLocation) {
            const currentPosition = await Location.getCurrentPositionAsync({
              accuracy: 1,
            })

            if (!currentPosition) {
              return alert('UCROP.IT necesita permisos de localización')
            }

            location = {
              latitude: currentPosition.coords.latitude,
              longitude: currentPosition.coords.longitude,
            }
          }
        } catch (error) {
          if (error.message === 'User rejected permissions') {
            Alert.alert(
              'Sin permisos de la camara',
              'Por favor vaya a la configuración y activar manualmente',
              [
                { text: 'Cancelar', onPress: () => console.warn('cancel') },
                {
                  text: 'Permitir',
                  onPress: () => Linking.openURL('app-settings:'),
                },
              ],
              { cancelable: false }
            )
          }
        }
      }

      let newUri

      if (Platform.OS === 'android') {
        newUri = result.uri
      } else {
        newUri = 'file:///' + result.uri.split('file:/').join('')
      }

      const file = {
        uri: newUri,
        name: result.name || result.uri.split('/').pop(),
        type: mime.getType(newUri),
      }

      setTimeout(() => {
        setLoading(false)

        handleFile({
          location,
          file: file,
          preview: accept === 'image/*' ? newUri : {},
        })
      }, 2500)
    } catch (error) {
      console.warn(error.message)
    }
  }

  function _chooseMultimedia() {
    const options = [
      t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_10,
      t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_11,
      t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_3,
    ]

    const cancelButtonIndex = 3

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          _pickDocument()
        } else if (buttonIndex === 1) {
          _pickDocument('camera')
        }
      }
    )
  }

  return (
    <>
      {loading ? (
        <ModalLoading
          isLoading={true}
          handleClose={() => setLoading(false)}
          animation={LoadingAnimation}
          animationStyle={{
            width: 150,
            height: 150,
          }}
          title={t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_13}
          loadingLabels={[
            t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_14,
            t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_15,
          ]}
        />
      ) : Platform.OS === 'android' || Platform.OS === 'ios' ? (
        <Pressable
          style={style}
          onPress={() => {
            !showCameraRollOption ? _pickDocument() : _chooseMultimedia()
          }}
        >
          {children}
        </Pressable>
      ) : (
        <UploadWeb
          handleFile={handleFile}
          accept={accept}
          style={style}
          toggleModalError={toggleModal}
          t={t}
        >
          {children}
        </UploadWeb>
      )}
      <ModalConfirm
        visible={isModalVisible}
        onClose={closeModal}
        onConfirm={closeModal}
        title={t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_7}
        contentText={
          accept === '.kmz'
            ? t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_5
            : t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_6
        }
        confirmText={t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_8}
      />
    </>
  )
}

export default UploadInput
