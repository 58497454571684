import { useSelector } from 'react-redux'

import { GlobalStateInterface } from '@store/interfaces'

export const useOfflineTypes = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )

  const getTypes = async (name: string) => {
    if (!database) {
      return []
    }

    const results = await database.getAllAsync(`SELECT * FROM ${name}_types`)

    return results.map((element: any) => JSON.parse(element.data))
  }

  const storeType = async (name: string, data: any) => {
    if (!database) {
      return []
    }

    await database.execAsync(
      `CREATE TABLE IF NOT EXISTS ${name}_types (id integer primary key not null, _id text, data text);`
    )

    await Promise.all(
      data.map(async (element: any) => {
        const results = await database.getAllAsync(
          `SELECT * FROM ${name}_types where _id = '${element._id}'`
        )

        if (results.length === data.length) {
          return
        }

        await database.runAsync(
          `INSERT INTO ${name}_types (_id, data) values (?,?)`,
          [element._id, JSON.stringify(element)]
        )
      })
    )

    return
  }

  return { storeType, getTypes }
}
