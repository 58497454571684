import { useSelector } from 'react-redux'

import useNetwork from '@utils/network'
import activityTypes from '@constants/activityTypes'
import { useOfflineCrops } from '@offline/queries/crops'
import { getLocalStorage, setLocalStorage } from '@utils/common'
import { GlobalStateInterface } from '@store/interfaces'

export const CREATE_TABLE_QUERY_SUPPLIES =
  'CREATE TABLE IF NOT EXISTS supplies (id integer primary key not null, _id text, name text, brand text, company text, alphaCode text, eiq real, activities text, cropTypes text, tags text, data text);'
const INSERT_QUERY =
  'INSERT INTO supplies (_id, name, brand, company, alphaCode, eiq, activities, cropTypes, tags, data) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?);'
const SELECT_QUERY = 'SELECT * FROM supplies'
const COUNT_QUERY = 'SELECT COUNT(id) AS count FROM supplies;'
const DROP_QUERY = 'DROP TABLE IF EXISTS supplies;'
const LAST_VERSION_KEY = 'lastVersionSupply'

/*
const FIELDS_TABLE = [
  'id',
  '_id',
  'name',
  'brand',
  'company',
  'alphaCode',
  'eiq',
  'activities',
  'cropTypes',
  'tags',
  'data',
]
*/

let isValidatingVersion = false

export const useOfflineSupplies = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )

  const { findOneCrop } = useOfflineCrops()

  const { doRequest } = useNetwork()

  const initOfflineSupply = async () => {
    if (!database) {
      return
    }

    await createSupplyTable()

    return
  }

  const createSupplyTable = async () => {
    await database.execAsync(CREATE_TABLE_QUERY_SUPPLIES)

    return
  }

  const dropSupplyTable = async () => {
    await database.execAsync(DROP_QUERY)

    return
  }

  const validateVersionSupplies = async () => {
    if (!database || isValidatingVersion) {
      return
    }

    isValidatingVersion = true

    const supply = await findOneSupply()

    let hasColumnsCorrect = true

    /**
     * TODO: Need to implement other way to check column names
     */
    /*
    if (supply) {
      const keys = Object.keys(supply)
      const fields: any = []

      FIELDS_TABLE.forEach((field) => {
        fields.push(keys.includes(field))
      })

      console.log('FIELDS_TABLE')
      console.log(FIELDS_TABLE)

      console.log('keys')
      console.log(keys)

      console.log('fields')
      console.log(fields)

      hasColumnsCorrect = fields.every((element: any) => element)
    }
    */

    const lastVersionSupplyLocal = await getLocalStorage(LAST_VERSION_KEY)

    const { data } = await doRequest({
      method: 'GET',
      url: 'supplies/eiq/last-version',
      displayAlert: false,
    })

    const isVersionSupplyEquals = lastVersionSupplyLocal === data.versionDate

    if (!supply || !hasColumnsCorrect || !isVersionSupplyEquals) {
      await dropSupplyTable()
      await createSupplyTable()

      const crop = await findOneCrop()

      if (crop) {
        const alphaCode =
          crop.company.country.alpha3Code || crop.company.country.alphaCode

        await setLocalStorage(LAST_VERSION_KEY, data.versionDate)
        await syncSupplies(alphaCode)
      }
    }

    isValidatingVersion = false

    return
  }

  const syncSupplies = async (alphaCode: string) => {
    if (!database) {
      return
    }

    const quantitiesResponse = await doRequest({
      method: 'GET',
      url: 'supplies/quantities',
      displayAlert: false,
      params: {
        alphaCode,
      },
    })

    const suppliesQuantity = quantitiesResponse.data

    let pages = 1
    const limit = 5000

    if (suppliesQuantity > limit) {
      pages = Math.ceil(suppliesQuantity / limit)
    }

    await resetSupplies()

    await asyncLoopsChunk(alphaCode, 1, pages, limit)
  }

  const asyncLoopsChunk = async (
    alphaCode: string,
    page: number,
    pages: number,
    limit: number
  ) => {
    return new Promise((resolve) => {
      let counter = 0

      const helperChunk = async (page: number, cb: Function) => {
        try {
          if (page > pages) {
            return cb({ error: null, counter })
          } else {
            helperChunk(page + 1, cb)
          }

          const params = {
            alphaCode,
            limit,
            skip: limit * (page - 1),
          }

          const response = await doRequest({
            method: 'GET',
            url: 'supplies/for-offline',
            displayAlert: false,
            params,
          })

          for (const element of response.data) {
            const activities = element?.typeId?.activities
              ? element.typeId.activities
              : []
            const cropTypes = element?.typeId?.cropTypes
              ? element.typeId.cropTypes
              : []
            const tags = element?.typeId?.tags ? element.typeId.tags : []

            const dataToInsert = [
              element._id,
              element.name,
              element.brand ?? '',
              element.company ?? '',
              element.alphaCode ?? '',
              element.eiqTotal ?? '',
              activities.join(),
              cropTypes.join(),
              tags.join(),
              JSON.stringify(element),
            ]

            await database.runAsync(INSERT_QUERY, dataToInsert)

            counter++
          }
        } catch (error) {
          console.warn('SUPPLIES asyncLoopsChunk > helperChunk ERROR')
          console.warn(error)

          return cb({ error })
        }
      }

      helperChunk(page, (results: any) => resolve(results))
    })
  }

  const getSupplies = async ({
    searchValue = '',
    activityType,
    cropType,
    tag,
    orderByAscending,
    limit,
    skip,
  }: any) => {
    if (!database) {
      return []
    }

    let sqlStatement = `${SELECT_QUERY} WHERE (name LIKE ? OR brand LIKE ? OR company LIKE ?)`

    const args = [`%${searchValue}%`, `%${searchValue}%`, `%${searchValue}%`]

    if (activityType) {
      sqlStatement = `${sqlStatement} AND (activities = ? OR activities LIKE ? OR activities LIKE ? OR activities LIKE ?)`

      args.push(activityType)
      args.push(`%,${activityType}`)
      args.push(`${activityType},%`)
      args.push(`%,${activityType},%`)
    }

    if (activityType === activityTypes.ACT_SOWING.key && cropType) {
      sqlStatement = `${sqlStatement} AND (cropTypes = ? OR cropTypes LIKE ? OR cropTypes LIKE ? OR cropTypes LIKE ?)`

      args.push(cropType)
      args.push(`%,${cropType}`)
      args.push(`${cropType},%`)
      args.push(`%,${cropType},%`)
    }

    if (tag) {
      sqlStatement = `${sqlStatement} AND (tags = ? OR tags LIKE ? OR tags LIKE ? OR tags LIKE ?)`

      args.push(tag)
      args.push(`%,${tag}`)
      args.push(`${tag},%`)
      args.push(`%,${tag},%`)
    }

    if (orderByAscending) {
      sqlStatement = `${sqlStatement} ORDER BY ${orderByAscending} ASC`
    }

    sqlStatement = `${sqlStatement} LIMIT ? OFFSET ?`

    args.push(limit ? limit + 1 : 16)
    args.push(skip || 0)

    const results = await database.getAllAsync(sqlStatement, args)

    const supplies = results.map((element: any) => JSON.parse(element.data))

    let hasMoreElements = false

    if (supplies.length === limit + 1) {
      hasMoreElements = true

      supplies.pop()
    }

    const dataToSend = {
      results: supplies,
      hasMoreElements,
    }

    return dataToSend
  }

  const findOneSupply = async () => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      'SELECT * from supplies LIMIT 1;'
    )

    if (!result) {
      return
    }

    return JSON.parse(result.data)
  }

  const countSuppliesOffline = async () => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(COUNT_QUERY)

    return result.count
  }

  const resetSupplies = async () => {
    if (!database) {
      return
    }

    await database.runAsync('DELETE from supplies')

    return
  }

  return {
    initOfflineSupply,
    syncSupplies,
    validateVersionSupplies,
    getSupplies,
    resetSupplies,
    findOneSupply,
    countSuppliesOffline,
  }
}
