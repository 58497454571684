import { StyleSheet } from 'react-native'
import { FAB as Fab } from 'react-native-paper'

import { green500, white } from '@common/styles'
import { CommonIcon } from '@common/components/icons'

export const FabButton = ({
  backgroundColor = green500,
  color = white,
  iconName,
  style,
  onPress,
  visible = true,
  testID = null,
}: any) => {
  return (
    <Fab
      style={[styles.fab, { backgroundColor }, style]}
      color={color}
      icon={(props) => <CommonIcon {...props} name={iconName || 'PLUS'} />}
      onPress={onPress}
      visible={visible}
      testID={testID}
    />
  )
}

const FAB_MARGIN_BOTTOM = 20

const styles = StyleSheet.create({
  fab: {
    backgroundColor: green500,
    position: 'absolute',
    color: white,
    right: 15,
    bottom: FAB_MARGIN_BOTTOM,
    borderRadius: 100,
  },
})
