import { useEffect, useContext, useState } from 'react'
import { Platform } from 'react-native'
import { useSelector } from 'react-redux'

import { CommonContext } from '@contextState/common'
import { useOfflineSupplies } from '@offline/queries/supplies'
import useNetwork from '@utils/network'
import FullLoaderCommon from './components/FullLoaderCommon'
import { logLastEntry } from '@services/analytics'
import { MODAL_ERRORS } from '@utils/constants'
import { useTableCreationInitializationForOffline } from '@offline/data_base/initTablesDataBase'
import { useOfflineCrops } from '@offline/queries/crops'
import { useOfflineDrafts } from '@offline/queries/drafts'
import { ResponsiveLayout } from '@common/components'
import { useAuth } from '@common/hooks'
import { GlobalStateInterface } from '@store/interfaces'

const urls = {
  urlCrop: '/crops/',
  urlActivities: '/crops/activities/',
  urlLicenses: '/licenses/',
}

const CommonLoader = () => {
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )
  const {
    fetchCommonIsComplete,
    setIsFetchingCommonSuccess,
    setErrorDeepLink,
    setIsSupportsDatabase,
  }: any = useContext(CommonContext)
  const { isAuth, user, handleUser, fetchUser } = useAuth()
  const { initializeOfflineTables } = useTableCreationInitializationForOffline()
  const { validateVersionSupplies } = useOfflineSupplies()
  const { insertCrop, findOneCrop } = useOfflineCrops()
  const { insertDraftOffline } = useOfflineDrafts()
  const { doRequest } = useNetwork()
  const [isFetchReady, setFetchReady] = useState(false)
  const [isCompanySelected, setIsCompanySelected] = useState(true)

  const sendLastEntry = async () => {
    if (isConnected) {
      const dataToSend = {
        os: Platform.OS,
        email: user?.email,
        date: new Date(),
      }

      logLastEntry(dataToSend)
    }
  }

  const initOffline = async () => {
    if (Platform.OS === 'web') {
      return
    }

    if (!database) {
      setIsSupportsDatabase(false)

      return true
    }

    setIsSupportsDatabase(true)

    await initializeOfflineTables()

    const existCrop = await findOneCrop()

    if (existCrop) {
      return
    }

    let currentUser

    if (!user) {
      currentUser = await fetchUser()
    } else {
      currentUser = user
    }

    const identifier = currentUser?.config?.companySelected?.identifier

    if (!identifier) {
      return
    }

    const params = {
      identifier,
      createdAt: Date.now(),
    }

    let crops = []

    try {
      const { data } = await doRequest({
        method: 'GET',
        url: 'crops/offline',
        params,
        displayAlert: false,
      })

      crops = data
    } catch (error) {
      console.warn(error)
    }

    await Promise.all(
      crops.map(async (crop: any) => {
        insertCrop(crop, identifier)

        const {
          data: { data: drafts },
        } = await doRequest({
          method: 'GET',
          url: 'activities-drafts',
          params: {
            cropId: crop._id,
          },
          displayAlert: false,
        })

        for (const draft of drafts) {
          const dataToInsert = {
            ...draft,
            isSynchronized: true,
          }

          insertDraftOffline(dataToInsert, currentUser)
        }
      })
    )

    return
  }

  const fetchInitial = async () => {
    await Promise.all([sendLastEntry(), initOffline()])

    if (isAuth && Platform.OS !== 'web' && isConnected) {
      await validateVersionSupplies()
    }

    setFetchReady(true)
  }

  const selectCompany = async (companyId: string) => {
    try {
      const { config } = user
      const { companySelected } = config
      if (companySelected._id !== companyId) {
        const data = {
          companySelected: companyId,
        }
        const configResponse = await doRequest({
          method: 'PUT',
          url: `configurations/${user?.config?._id}`,
          data,
          displayAlert: false,
        })
        await handleUser(configResponse.data)
        await fetchUser()
      }
    } catch (error) {
      setErrorDeepLink(MODAL_ERRORS.UNAUTHORIZED)
    }
    setIsCompanySelected(true)
  }

  useEffect(() => {
    if (fetchCommonIsComplete && isAuth && isFetchReady && isCompanySelected) {
      setIsFetchingCommonSuccess(true)
    }
  }, [fetchCommonIsComplete, isAuth, isFetchReady, isCompanySelected])

  useEffect(() => {
    if (isAuth && !isFetchReady) {
      fetchInitial()
    }
  }, [])

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (
        window.location.pathname.includes(urls.urlCrop) ||
        window.location.pathname.includes(urls.urlActivities) ||
        window.location.pathname.includes(urls.urlLicenses)
      ) {
        const params = new URLSearchParams(window.location.search)
        const companyId = params.get('companyId')
        if (companyId) {
          setIsCompanySelected(false)
          selectCompany(companyId)
        }
      }
    }
  }, [])

  return (
    <ResponsiveLayout>
      <FullLoaderCommon />
    </ResponsiveLayout>
  )
}

export default CommonLoader
