import { UNITS_TEMPERATURE } from '@common/constants'
import { useAuth, useCompanyInfo } from '@common/hooks'
import { makeRequest } from '@common/services'
import { buildEstablishmentsToSelector, truncateSurface } from '@common/utils'
import { LanguageContext } from '@contextState/language'
import { HeaderFieldSelection } from '@modules/activities/components'
import { LotInterface } from '@modules/activities/interfaces'
import { useOfflineCommon } from '@offline/queries/common'
import { useNavigation } from '@react-navigation/native'
import {
  GlobalStateInterface,
  SelectionPageReducerInterface,
} from '@store/interfaces'
import {
  checkedSelectedItem,
  disabledItems,
  replaceItem,
  resetDataSelection,
  selectAllSubItems,
  setItemsSelection,
  startDataSelection,
} from '@store/slices'
import { unitMeasureSystem } from '@utils/constants'
import { AxiosResponse } from 'axios'
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FarmWaterFootprint } from '../interfaces'

interface ResponseTypes {
  _id: string
  cftId?: number
  codeLabel?: string
  disabled?: boolean
  keyLabel?: string
}

interface ItemSelectType {
  label: string
  value: string
}
interface PropertiesGetSoil {
  farmUuid?: string
  identifier: string
  lots: LotInterface[]
  newLabel: string
  totalSurface: number
}

export const useFetchWaterCalculation = ({
  formik: { values, setFieldValue, setFieldTouched },
}: any): any => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { t } = useContext(LanguageContext)
  const { config } = useAuth()
  const [weatherTypes, setWeatherTypes] = useState<ItemSelectType[]>([])
  const [soilTypes, setSoilTypes] = useState<ItemSelectType[]>([])
  const [landTypes, setLandTypes] = useState<ItemSelectType[]>([])
  const [humidityTypes, setHumidityTypes] = useState<ItemSelectType[]>([])
  const weatherRef = useRef(null)
  const soilRef = useRef(null)
  const landRef = useRef(null)
  const humidityRef = useRef(null)
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )
  const { itemsSelected }: SelectionPageReducerInterface = useSelector(
    (state: GlobalStateInterface) => state.selectionPageReducer
  )
  const { showIndividuals } = useOfflineCommon()
  const { identifier } = useCompanyInfo()
  const unitTemperature =
    config?.companySelected?.unitMeasureSystem === unitMeasureSystem.METRIC
      ? UNITS_TEMPERATURE.C
      : UNITS_TEMPERATURE.F
  useEffect(() => {
    fetchInitAll()
  }, [])

  useEffect(() => {
    if (values.draftId || values.activityId) {
      dispatch(startDataSelection({ itemsSelected: values.lots?.value ?? [] }))
    }
    return () => {
      dispatch(resetDataSelection())
    }
  }, [values.draftId, values.activityId])

  const fetchInitAll = async () => {
    fetchWeatherTypes()
    fetchSoilTypes()
    fetchLandTypes()
    fetchHumidityTypes()
  }

  const fetchWeatherTypes = async () => {
    const weatherTypesResult: AxiosResponse = await makeRequest({
      url: 'weather-types',
    })
    setWeatherTypes(
      weatherTypesResult.data.map((weatherType: ResponseTypes) => ({
        value: weatherType._id,
        label: weatherType.codeLabel,
      }))
    )
  }

  const fetchSoilTypes = async () => {
    const soilTypesResult: AxiosResponse = await makeRequest({
      url: 'soil-types',
    })
    setSoilTypes(
      soilTypesResult.data.map((soilType: ResponseTypes) => ({
        value: soilType._id,
        label: soilType.codeLabel,
      }))
    )
  }

  const fetchLandTypes = async () => {
    const landTypesResult: AxiosResponse = await makeRequest({
      url: 'land-types',
    })
    setLandTypes(
      landTypesResult.data.map((landType: ResponseTypes) => ({
        value: landType._id,
        label: landType.codeLabel,
      }))
    )
  }

  const fetchHumidityTypes = async () => {
    const humidityTypesResult: AxiosResponse = await makeRequest({
      url: 'humidity-types',
    })
    setHumidityTypes(
      humidityTypesResult.data.map((humidityType: ResponseTypes) => ({
        value: humidityType._id,
        label: humidityType.codeLabel,
      }))
    )
  }
  const onClickField = (item: any, indexFarm: number, indexField: number) => {
    const selected = !item.selected
    dispatch(
      replaceItem({
        item: { ...item, selected },
        indexItem: indexFarm,
        indexSubItem: indexField,
        checkDisabled: true,
      })
    )
    dispatch(
      disabledItems({
        indexItem: indexFarm,
      })
    )
    dispatch(
      checkedSelectedItem({
        indexItem: indexFarm,
      })
    )
  }

  const onClickFarm = (indexFarm: number) => {
    dispatch(
      selectAllSubItems({
        indexItem: indexFarm,
      })
    )
    dispatch(
      disabledItems({
        indexItem: indexFarm,
      })
    )
  }

  const callback = (lots: LotInterface[], totalSurface: number) => {
    const newLabel =
      lots.length > 1
        ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
        : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')
    const farmUuid = lots[0].farmUuid
    const lotsSend = lots.map((lot) => {
      const {
        ElementRender,
        onClickElement,
        centerBound,
        centerBoundGoogle,
        centroid,
        geometryData,
        ...newLot
      } = lot
      return newLot
    })

    getPropertiesSoil({
      farmUuid,
      identifier,
      newLabel,
      lots: lotsSend,
      totalSurface,
    })
  }

  const onPressLotsInput = async () => {
    const activityId = values.activityId
    let farmsAndFields: any = []
    if (isConnected) {
      const response = await makeRequest({
        method: 'GET',
        url: `crops/${values.cropId}/establishments`,
        params: {
          cropId: values.cropId,
          activityId: activityId,
        },
      })
      farmsAndFields = response.data
    } else {
      const cropResponse = await showIndividuals('crops', values.cropId)
      if (activityId) {
        const offlineActivityData = await showIndividuals(
          'activities',
          activityId
        )
        farmsAndFields = cropResponse.establishments.map((element: any) => ({
          ...element,
          lots: element.lots.filter((lot: any) =>
            offlineActivityData.lots.some(
              (lotActivity: any) => lotActivity._id === lot._id
            )
          ),
        }))
      } else {
        farmsAndFields = cropResponse.establishments.map((element: any) => ({
          ...element,
          lots: element.lots,
        }))
      }
    }
    if (farmsAndFields) {
      const allDisabled = Boolean(activityId)
      const result = buildEstablishmentsToSelector({
        fields: farmsAndFields,
        itemsSelected,
        onClickField,
        onClickFarm,
        allDisabled,
      })
      dispatch(
        setItemsSelection({
          items: result,
          callback: callback,
          HeaderRender: HeaderFieldSelection,
          fieldSum: 'surface',
        })
      )
      navigation.navigate('SelectItemsWithMultiLevels', {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_7'),
        textButton: t('VIEWS.LOTS_ADD.TEXT_8'),
      })
    }
  }

  const setDataSelect = (
    data: ResponseTypes,
    nameField: string,
    selectRef: MutableRefObject<null>
  ) => {
    if ((!data?._id || !data?.keyLabel) && selectRef.current) {
      setFieldValue(nameField, {
        value: '',
        label: '',
      })
      //@ts-ignore
      selectRef.current.reset()
      return
    }
    const result = {
      value: data._id,
      label: data.keyLabel,
    }
    setFieldValue(nameField, result)
    setFieldTouched(nameField)
  }

  const getPropertiesSoil = async ({
    farmUuid,
    identifier,
    lots,
    newLabel,
    totalSurface,
  }: PropertiesGetSoil) => {
    if (farmUuid && identifier) {
      const result = await makeRequest({
        method: 'GET',
        url: `/farms/${farmUuid}/companies/${identifier}`,
      })

      const farm: FarmWaterFootprint = result.data

      const { soilType, landType, temperature, humidityType, weatherType } =
        farm
      const farms = [{ farm: farm._id }]

      setFieldValue('farms', farms)
      setDataSelect(soilType, 'soilType', soilRef)
      setDataSelect(landType, 'landType', landRef)
      setDataSelect(humidityType, 'humidityType', humidityRef)
      setDataSelect(weatherType, 'weatherType', weatherRef)

      setFieldValue('temperature', temperature ? String(temperature) : '')
    }

    setFieldValue('lots', {
      value: lots,
      label: `${lots.length} ${newLabel}`,
    })

    setFieldValue('surface', String(truncateSurface(totalSurface)))
    navigation.goBack()
  }

  const isLotsSelected = Boolean(values.lots?.value?.length)

  return {
    onPressLotsInput,
    weatherTypes,
    soilTypes,
    isLotsSelected,
    landTypes,
    humidityTypes,
    t,
    unitTemperature,
    weatherRef,
    soilRef,
    landRef,
    humidityRef,
  }
}
