import { useCallback, useContext, useEffect, useState } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'

import { CropContext } from '@contextState/crop'
import { CommonContext } from '@contextState/common'

import useNetwork from '@utils/network'

import { selectDraftCrop } from '@store/selectors/draftCrops'
import { LanguageContext } from '@contextState/language'

import { draftCropActions } from '@store/actions'

import { formatDateMoment } from '@utils/date'
import { ORDER_MENU_ENUM } from '@modules/common/utils'

import { INITIAL_PAGE } from '../utils'
import { useAuth, useCropMenu } from '@modules/common/hooks'
import { userPermissions } from '@utils'
import { useOfflineCrops } from '@modules/common/hooks'
import { Platform } from 'react-native'

export const useCropList = () => {
  const { t, i18n } = useContext(LanguageContext)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const {
    filters,
    orderCropsBy,
    setOrderCropsBy,
    resetFilters,
    resetOrderCropsBy,
    fetchCropsForFilter,
  } = useContext(CommonContext)
  const {
    config,
    selectedProfile,
    companyAllow,
    user,
    canSynchronized,
    isCompanyUcropit,
    isPendingAuthorization,
  } = useAuth()
  const { disableCropOffline } = useOfflineCrops()

  const {
    fetchCrops,
    data,
    setDrafts,
    setIsDownloaded,
    setCurrentCollaborator,
    currentPage,
    setCurrentPage,
    pagination,
    isLoadingMore,
  } = useContext(CropContext)

  const { companyAdmin, roleSelected } = user.config ?? {}

  const navigation = useNavigation()

  const [crops, setCrops] = useState([])
  const [cropDraft, setCropDraft] = useState(null)
  const [countrySupplies, setCountrySupplies] = useState('')
  const [visibleErrorSupplies, setVisibleErrorSupplies] = useState(false)
  const [selected, setSelected] = useState({})
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const dispatch = useDispatch()

  const hasPermissionCreate = Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.CREATE_CROP
    )
  )

  const nextPage = () => {
    const nextPage = currentPage + 1
    if (isLoadingMore || nextPage > pagination?.totalPages) return

    setCurrentPage(nextPage)
  }

  const { doRequest } = useNetwork()

  const valuesForm = useSelector(
    selectDraftCrop(config.companySelected?.identifier)
  )

  const onSuccessEnableCrop = (crop) => {
    changeDownloadedInCrop(crop, true)
  }

  const onErrorEnableCrop = (countryName) => {
    setCountrySupplies(countryName)
    setVisibleErrorSupplies(true)
  }

  const onSuccessDisableCrop = (crop) => {
    changeDownloadedInCrop(crop, false)
  }

  const onDeleteCrop = (crop) => {
    setSelected(crop)
    setShowDeleteDialog(true)
  }

  const onDeleteDraft = () => {
    dispatch(
      draftCropActions.clearDraftCrop(config.companySelected?.identifier)
    )
  }

  const { onPressCropMenu } = useCropMenu({
    onDeleteDraft,
    onDeleteCrop,
    onSuccessEnableCrop,
    onErrorEnableCrop,
    onSuccessDisableCrop,
  })

  useEffect(() => {
    if (!isConnected) {
      return
    }

    setCurrentPage(INITIAL_PAGE)
    resetFilters()
    resetOrderCropsBy()
    fetchCropsForFilter()
  }, [config.companySelected, isConnected])

  useEffect(() => {
    setCurrentPage(INITIAL_PAGE)

    return () => {
      setCrops([])
      setCropDraft(null)
      setDrafts([])
    }
  }, [filters])

  useFocusEffect(
    useCallback(() => {
      getCropDraft()
      getCrops()
    }, [filters, config.companySelected, valuesForm, currentPage, orderCropsBy])
  )

  useEffect(() => {
    const crops = cropDraft ? [cropDraft, ...data] : data
    setCrops(crops)
  }, [data, cropDraft])

  const getCropDraft = useCallback(() => {
    if (valuesForm) {
      setCropDraft({
        ...valuesForm,
        status: 'DRAFT',
      })
    } else {
      setCropDraft(null)
    }
  }, [valuesForm, config.companySelected, isConnected])

  const getCrops = async () => {
    try {
      const params = {
        identifier:
          companyAdmin?.identifier ?? config.companySelected?.identifier,
        companySelectedIdentifier: config.companySelected?.identifier,
        companyId: config?.companySelected
          ? config?.companySelected?._id
          : undefined,
        cropTypes: filters.cropTypes.length ? filters.cropTypes : undefined,
        companies: filters.companies.length ? filters.companies : undefined,
        collaborators: filters.collaborators.length
          ? filters.collaborators
          : undefined,
        cropVolume: filters.cropVolume !== 0 ? filters.cropVolume : undefined,
      }

      const forceOffline = orderCropsBy?.key === ORDER_MENU_ENUM.ENABLED_OFFLINE

      if (
        !forceOffline &&
        orderCropsBy !== null &&
        orderCropsBy.key !== ORDER_MENU_ENUM.LAST_UPLOADED &&
        orderCropsBy.key !== ORDER_MENU_ENUM.ENABLED_OFFLINE &&
        isConnected
      ) {
        params.sort = orderCropsBy.key
      } else {
        delete params.sort
      }

      await fetchCrops(params, forceOffline)
      await canSynchronized(config.companySelected?.identifier)
    } catch (error) {
      console.warn(error)
    }
  }

  const onPressCrop = async (crop) => {
    if (crop.status === 'DRAFT') {
      return navigation.navigate('CropCreate', { isDraft: true })
    }

    if (!crop.company) {
      return navigation.navigate('CropCompany', { id: crop._id })
    }

    const farms = crop.lots || crop.establishments

    const hasLots = Boolean(
      farms?.find(
        (farm) => Boolean(farm.data?.length) || Boolean(farm.lots?.length)
      )
    )

    if (!hasLots) {
      return navigation.navigate('CropFarmList', { cropId: crop._id })
    }

    if (crop.members) {
      await setCurrentCollaborator(
        crop.members.find((element) => element?.user?._id === user._id)
      )
    }

    setIsDownloaded(crop.downloaded)

    navigation.navigate('ActivityList', { cropId: crop._id })
  }

  /**
   *
   * @param {object} crop
   * @param {array<object>} crop.members
   * @param {boolean} isOfflineEnabled
   *
   * @return {void}
   */
  const changeDownloadedInCrop = async (crop, isOfflineEnabled) => {
    const { members } = crop

    const cropIndex = data.findIndex((item) => item._id === crop._id)

    if (cropIndex === -1) return

    if (
      !isOfflineEnabled &&
      orderCropsBy?.key === ORDER_MENU_ENUM.ENABLED_OFFLINE
    ) {
      const newData = [...data]
      newData.splice(cropIndex, 1)
      setDrafts(newData)
      return
    }

    const memberIndex = members.findIndex(
      (member) => member?.user?._id === user._id
    )

    if (memberIndex === -1) return

    const member = members[memberIndex]
    member.isOfflineEnabled = isOfflineEnabled

    members[memberIndex] = member

    crop.downloaded = isOfflineEnabled
    crop.members = members

    const newCrops = [...data]
    newCrops[cropIndex] = crop

    setDrafts(newCrops)
  }

  async function handleDeleteCrop() {
    try {
      if (Platform.OS !== 'web') {
        const hasCropOffline = selected.members?.find(
          (element) =>
            element.user?._id === user._id && element.isOfflineEnabled
        )

        if (hasCropOffline) {
          await disableCropOffline({ crop: selected })
        }
      }

      await doRequest({
        method: 'DELETE',
        url: `crops/${selected._id}`,
        version: 'v2',
      })

      setDrafts(data.filter((el) => el._id !== selected._id))

      setShowDeleteDialog(false)
    } catch (e) {
      console.warn(e)
    }
  }

  const handleOrderChange = (orderOption) => {
    setCurrentPage(INITIAL_PAGE)
    setOrderCropsBy(orderOption)
  }

  const subTitleList = useCallback(
    ({ dateCrop, dateHarvest, status }) => {
      let datecrop, dateharvest

      if (status === 'DRAFT') {
        datecrop = dateCrop.value
        dateharvest = dateHarvest.value
      } else {
        datecrop = dateCrop
        dateharvest = dateHarvest
      }

      return `${t('VIEWS.CROP.TEXT_9')}: ${formatDateMoment(
        datecrop,
        'MMM/YYYY',
        i18n.locale,
        true
      )}${
        dateharvest
          ? ' - ' + formatDateMoment(dateharvest, 'MMM/YYYY', i18n.locale, true)
          : ''
      }`
    },
    [t, i18n.locale]
  )

  const getCropStatus = (crop) =>
    !crop.company && crop.status !== 'DRAFT'
      ? t('VIEWS').CROP.TEXT_29
      : crop.status !== 'DRAFT'
      ? `${crop.pending.length} ${t('VIEWS').CROP.TEXT_30}`
      : crop.status

  const getCropType = (members) =>
    members?.find((member) => member?.user?._id === user._id)?.type

  const getIsCropOffline = (members) =>
    !!members?.find(
      (member) => member?.user?._id === user._id && member.isOfflineEnabled
    ) || orderCropsBy?.key === ORDER_MENU_ENUM.ENABLED_OFFLINE

  const getIsCropDownloaded = (isCropOffline) => isCropOffline || !isConnected

  return {
    crops,
    setCrops,
    cropDraft,
    setCropDraft,
    countrySupplies,
    setCountrySupplies,
    visibleErrorSupplies,
    setVisibleErrorSupplies,
    selected,
    setSelected,
    showDeleteDialog,
    setShowDeleteDialog,
    selectedProfile,
    companyAllow,
    isCompanyUcropit,
    nextPage,
    onPressCrop,
    onPressCropMenu,
    handleDeleteCrop,
    handleOrderChange,
    subTitleList,
    getCropStatus,
    getCropType,
    getIsCropOffline,
    getIsCropDownloaded,
    orderCropsBy,
    user,
    isLoadingMore,
    t,
    isConnected,
    hasPermissionCreate,
    isPendingAuthorization,
    config,
  }
}
