import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'

import { useAuth, useCompanyInfo } from '@common/hooks'
import useNetwork from '@utils/network'
import { activitiesActions } from '@store/actions'
import userTypes from '@constants/userTypes'
import { useOfflineCrops } from '@offline/queries/crops'
import { useSelector } from 'react-redux'

export const useFetchCrop = ({ cropId }: { cropId: string }) => {
  const dispatch = useDispatch()

  const { user, isVerifierUcropit } = useAuth()
  const { isConnected } = useSelector((state: any) => state.connectionReducer)

  const [isLoading, setIsLoading] = useState(true)
  const [crop, setCrop] = useState(null)

  const { doRequest } = useNetwork()
  const { identifier, companyId } = useCompanyInfo()
  const { findOneCropById } = useOfflineCrops()

  useFocusEffect(
    useCallback(() => {
      fetchData()
    }, [])
  )

  const fetchData = async () => {
    setIsLoading(true)

    let response

    if (isConnected) {
      const requestData = {
        method: 'GET',
        url: `crops/${cropId}`,
        version: 'v2',
        params: {
          companyId,
          createdAt: Date.now(),
        },
        displayAlert: false,
      }

      try {
        const { data } = await doRequest(requestData)

        response = data
      } catch (error) {
        setIsLoading(false)

        return
      }
    } else {
      response = await findOneCropById(cropId)
    }

    const parsedData = {
      ...response,
      activities: [],
    }

    const userType = String(
      response?.members?.find((member: any) => member?.user?._id === user._id)
        ?.type
    )

    dispatch(
      activitiesActions.setCanSeeVerification(
        isVerifierUcropit
          ? identifier === response?.identifier
          : userType !== userTypes.MARKETER.key
      )
    )

    setCrop(parsedData)

    setIsLoading(false)
  }

  return {
    isLoading,
    crop,
  }
}
