import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useDropzone } from 'react-dropzone'
import { Pressable, View } from 'react-native'
import { MB_SIZE_100 } from '@constants/files'

function UploadWeb({
  accept,
  handleFile,
  children,
  style,
  disabled,
  toggleModalError,
  autoOpen,
  testID = undefined,
  t,
}) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: accept || 'image/*',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        if (acceptedFiles[0].size > MB_SIZE_100) {
          toggleModalError(t('COMPONENTS.INPUTS.INPUT_FILE_UPLOAD.ERROR_SIZE'))
          return
        }
        handleFile({
          file: acceptedFiles[0],
          preview: !accept ? URL.createObjectURL(acceptedFiles[0]) : {},
        })
      } else {
        toggleModalError(t('COMPONENTS.COMMON.UPLOAD_INPUT.TEXT_6'))
      }
    },
    noClick: true,
    noKeyboard: true,
  })

  useEffect(() => {
    if (!autoOpen) {
      return
    }

    open()
  }, [autoOpen])

  return !disabled ? (
    <View {...getRootProps({ className: 'dropzone' })} style={style}>
      <input {...getInputProps()} />

      <Pressable style={styles.pressable} onPress={open} testID={testID}>
        {children}
      </Pressable>
    </View>
  ) : (
    children
  )
}

const styles = StyleSheet.create({
  pressable: {
    width: '100%',
    height: '100%',
  },
})

export default UploadWeb
