import { useSelector } from 'react-redux'

import {
  tableNamesIndividuals,
  tableNamesTypes,
} from '@offline/data_base/tableNames'
import { CREATE_TABLE_DEFAULT } from '@offline/data_base/sqlStatements'
import { useOfflineSupplies } from '@offline/queries/supplies'
import { useOfflineCrops } from '@offline/queries/crops'
import { useOfflineDrafts } from '@offline/queries/drafts'
import { useOfflineEvidenceDrafts } from '@offline/queries/evidenceDrafts'
import { useOfflineUser } from '@offline/queries/user'
import { useOfflineQueue } from '@offline/queries/queue'
import { useOfflineSubTypeActivities } from '@offline/queries/subTypeActivities'
import { GlobalStateInterface } from '@store/interfaces'

export const useTableCreationInitializationForOffline = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )
  const { initOfflineSupply } = useOfflineSupplies()
  const { initOfflineCrop } = useOfflineCrops()
  const { initOfflineDraft } = useOfflineDrafts()
  const { initOfflineEvidenceDrafts } = useOfflineEvidenceDrafts()
  const { initOfflineUser } = useOfflineUser()
  const { initOfflineQueue } = useOfflineQueue()
  const { initOfflineSubTypeActivities } = useOfflineSubTypeActivities()

  const initializeOfflineTables = async () => {
    if (!database) {
      return
    }

    await Promise.all([
      ...tableNamesTypes.map(async (tableName) => {
        const sqlStatement = CREATE_TABLE_DEFAULT.replace(
          'tableName',
          `${tableName}_types`
        )

        await database.execAsync(sqlStatement)
      }),
      ...tableNamesIndividuals.map(async (tableName) => {
        const sqlStatement = CREATE_TABLE_DEFAULT.replace(
          'tableName',
          `individuals_${tableName}`
        )

        await database.execAsync(sqlStatement)
      }),
    ])

    await Promise.all([
      initOfflineCrop(),
      initOfflineUser(),
      initOfflineSupply(),
      initOfflineDraft(),
      initOfflineEvidenceDrafts(),
      initOfflineQueue(),
      initOfflineSubTypeActivities(),
    ])
  }

  return {
    initializeOfflineTables,
  }
}
