import { useSelector } from 'react-redux'

import { GlobalStateInterface } from '@store/interfaces'

const CREATE_TABLE_QUERY_USER =
  'CREATE TABLE IF NOT EXISTS user (id integer primary key not null, data text, _id text);'

export const useOfflineUser = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )

  const initOfflineUser = async () => {
    if (!database) {
      return
    }

    await database.execAsync(CREATE_TABLE_QUERY_USER)
  }

  const syncUser = async (data: any) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * FROM USER WHERE _id = '${data._id}'`
    )

    if (!result) {
      await database.runAsync('INSERT INTO user (_id, data) values (?, ?)', [
        data._id,
        JSON.stringify(data),
      ])
    } else {
      await database.runAsync('UPDATE user SET data = ? where _id = ?', [
        JSON.stringify(data),
        data._id,
      ])
    }

    return
  }

  const selectUser = async () => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync('SELECT * from user')

    return JSON.parse(result.data)
  }

  const deleteUser = async () => {
    if (!database) {
      return
    }

    await database.runAsync('DELETE from user')

    return
  }

  return {
    initOfflineUser,
    syncUser,
    selectUser,
    deleteUser,
  }
}
