import { Platform } from 'react-native'

import { useOfflineSupplies } from '@offline/queries/supplies'
import { useOfflineCrops as offlineCropQueries } from '@offline/queries/crops'
import { useOfflineCommon } from '@offline/queries/common'
import { useOfflineDrafts } from '@offline/queries/drafts'
import useNetwork from '@utils/network'
import { useAuth } from './useAuth'

export const useOfflineCrops = () => {
  const { config, user } = useAuth()

  const { insertCrop, deleteCrop, findOneCrop } = offlineCropQueries()
  const { syncSupplies, findOneSupply, resetSupplies } = useOfflineSupplies()
  const { updateFarmsInCrop } = useOfflineCommon()
  const {
    insertDraftOffline,
    findDraftsByCropIdOffline,
    deleteDraftByIdOffline,
  } = useOfflineDrafts()
  const { doRequest } = useNetwork()

  /**
   *
   * @param {object} params
   * @param {object} params.crop
   * @param {Function=} params.onSuccessEnableCrop
   * @param {Function=} params.onErrorEnableCrop
   *
   * @return {Promise<void>}
   */
  const enableCropOffline = async ({
    crop,
    onSuccessEnableCrop,
    onErrorEnableCrop,
  }) => {
    try {
      const {
        _id: cropId,
        company: {
          _id: companyId,
          country: { alpha3Code: alphaCodeInCrop },
        },
      } = crop

      const supply = await findOneSupply()

      const alphaCodeInSupply = supply?.alpha3Code ?? supply?.alphaCode

      if (alphaCodeInSupply === alphaCodeInCrop || !alphaCodeInSupply) {
        doRequest({
          method: 'PUT',
          url: `crops/${cropId}/enableOffline`,
        })

        const { data: cropFetched } = await doRequest({
          method: 'GET',
          url: `crops/${cropId}`,
          version: 'v2',
          params: {
            companyId,
          },
          displayAlert: false,
        })

        const cropToInsert = {
          ...cropFetched,
          members: cropFetched.members.map((element) => ({
            ...element,
            isOfflineEnabled: element.user?._id === user._id,
          })),
        }

        insertCrop(cropToInsert, config?.companySelected?.identifier)

        const {
          data: { data: drafts },
        } = await doRequest({
          method: 'GET',
          url: 'activities-drafts',
          params: {
            cropId,
          },
          displayAlert: false,
        })

        for (const draft of drafts) {
          const dataToInsert = {
            ...draft,
            isSynchronized: true,
          }

          insertDraftOffline(dataToInsert, user)
        }

        await syncSupplies(alphaCodeInCrop)

        if (onSuccessEnableCrop) onSuccessEnableCrop(crop)
      } else {
        if (onErrorEnableCrop) onErrorEnableCrop(supply.countryId?.name)
      }
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   *
   * @param {object} params
   * @param {object} params.crop
   * @param {Function=} params.onSuccessDisableCrop
   *
   * @return {Promise<void>}
   */
  const disableCropOffline = async ({ crop, onSuccessDisableCrop }) => {
    try {
      const { _id: cropId } = crop

      doRequest({
        method: 'PUT',
        url: `crops/${cropId}/disableOffline`,
      })

      await deleteCrop(cropId)

      const existCrop = await findOneCrop()

      if (!existCrop) {
        resetSupplies()
      }

      const draftsToDelete = await findDraftsByCropIdOffline(cropId)

      await Promise.all(
        draftsToDelete.map(async (draft) => deleteDraftByIdOffline(draft._id))
      )

      if (onSuccessDisableCrop) {
        onSuccessDisableCrop(crop)
      }
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   *
   * @param {object} params
   * @param {object} params.cropId
   *
   * @return {Promise<boolean>}
   */
  const updateFarmsCropOffline = async ({ cropId }) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (Platform.OS === 'web') {
          return resolve({})
        }

        setTimeout(async () => {
          const { data: crop } = await doRequest({
            method: 'GET',
            url: `crops/${cropId}`,
            version: 'v2',
            params: {
              companyId: config.companySelected?._id,
            },
            displayAlert: false,
          })

          if (!crop) {
            return reject({
              error: true,
            })
          }

          const isOfflineEnabled = Boolean(
            crop.members?.find(
              ({ user: currentUser, isOfflineEnabled }) =>
                currentUser?._id === user._id && isOfflineEnabled
            )
          )

          if (!isOfflineEnabled) {
            return resolve({})
          }

          await updateFarmsInCrop({
            cropId: crop._id,
            farms: crop.establishments,
          })

          return resolve({})
        }, 3000)
      })

      return promise
    } catch (error) {
      console.error(error)

      return {
        error,
      }
    }
  }

  return {
    enableCropOffline,
    disableCropOffline,
    updateFarmsCropOffline,
  }
}
