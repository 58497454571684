import { useState, useEffect, useContext, useCallback, useRef } from 'react'

import { CommonContext } from '@contextState/common'
import { useCompanyInfo } from '@common/hooks'
import { useOfflineDrafts } from '@offline/queries/drafts'
import { useOfflineCrops } from '@offline/queries/crops'
import useNetwork from '@utils/network'
import { useActivityWrapper } from '../useActivityWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { activitiesActions } from '@store/actions'
import { IParams, IParamsDraft } from './interfaces'
import { ACTIVITY_CONDITIONS } from '@common/utils'
import { useFocusEffect } from '@react-navigation/native'
import { GlobalStateInterface } from '@store/interfaces'

const INIT_PAGE = 1
const LAST_PAGE = 1
const LIMIT = 15
const INIT_PAGINATION = {
  page: INIT_PAGE,
  limit: LIMIT,
  totalPages: LAST_PAGE,
}

export const useFetchActivities = ({ cropId }: { cropId: string }) => {
  const dispatch = useDispatch()

  const { isDraftsSynchronizing }: any = useContext(CommonContext)

  const { subTypeActivities } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )

  const { isConnected } = useSelector((state: any) => state.connectionReducer)

  const { activityStatus, activityType } = useSelector(
    (state: any) => state.activities
  )

  const thereIsFilterDraft = activityStatus.some(
    (condition: string) => condition === ACTIVITY_CONDITIONS.DRAFT.key
  )

  const activityStatusWithoutDraft = activityStatus.filter(
    (status: string) => status !== ACTIVITY_CONDITIONS.DRAFT.key
  )

  const [isLoading, setIsLoading] = useState(false)
  const [offlineCrop, setOfflineCrop]: any = useState()
  const [activities, setActivities]: any = useState([])

  const { doRequest } = useNetwork()
  const { identifier } = useCompanyInfo()
  const { findDraftsByCropIdOffline } = useOfflineDrafts()
  const { findOneCropById } = useOfflineCrops()
  const { wrapData } = useActivityWrapper({ subTypeActivities, offlineCrop })

  const paginationData: any = useRef(INIT_PAGINATION)

  useFocusEffect(
    useCallback(() => {
      if (!isConnected && !offlineCrop) {
        fetchOfflineCrop()

        return
      }

      paginationData.current = INIT_PAGINATION

      initFetchData()
    }, [
      isConnected,
      isDraftsSynchronizing,
      offlineCrop,
      activityStatus,
      activityType,
    ])
  )

  useEffect(() => {
    return () => {
      dispatch(activitiesActions.removeFilters())
    }
  }, [])

  useEffect(() => {
    if (isDraftsSynchronizing) {
      setActivities([])
    }
  }, [isDraftsSynchronizing])

  const initFetchData = async () => {
    if (isDraftsSynchronizing) {
      return
    }

    setIsLoading(true)

    const [drafts, activities]: any = await Promise.all([
      fetchDrafts(),
      fetchActivities(),
    ])

    const activitiesMerged = [...drafts, ...activities]

    dispatch(
      activitiesActions.setIsActivities(Boolean(activitiesMerged.length))
    )

    setActivities(activitiesMerged)

    setIsLoading(false)
  }

  const fetchOfflineCrop = async () => {
    let offlineCrop

    if (!isConnected) {
      offlineCrop = await findOneCropById(cropId)
    }

    setOfflineCrop(offlineCrop)
  }

  const fetchDrafts = async () => {
    if (paginationData.current?.page !== 1) {
      return []
    }

    let parsedDrafts = []

    if (isConnected) {
      const params: IParamsDraft = {
        cropId,
      }

      const requestData = {
        method: 'GET',
        url: 'activities-drafts',
        params,
        displayAlert: false,
      }

      let response

      if (
        (activityStatus.length && thereIsFilterDraft) ||
        !activityStatus.length
      ) {
        try {
          const { data } = await doRequest(requestData)

          response = data
        } catch (error) {
          console.error(error)

          return
        }
      } else {
        response = { cantElements: 0, data: [] }
      }

      const { data } = wrapData({
        currentData: [],
        newData: response.data,
      })

      parsedDrafts = data
    } else {
      const responseDrafts = await findDraftsByCropIdOffline(cropId)

      const { data } = wrapData({
        currentData: [],
        newData: responseDrafts,
      })

      parsedDrafts = data
    }

    return parsedDrafts
  }

  const fetchActivities = async () => {
    if (!isConnected) {
      return []
    }

    const params: IParams = {
      identifier,
      cropId,
      page: paginationData.current.page,
      limit: paginationData.current.limit,
    }

    if (activityStatusWithoutDraft.length) {
      params.condition = activityStatusWithoutDraft.toString()
    }

    if (activityType.length) {
      params.tag = activityType.toString()
    }

    const requestData = {
      method: 'GET',
      url: 'activities',
      version: 'v2',
      params,
      displayAlert: false,
    }

    let response

    if (
      !activityStatus.length ||
      (activityStatus.length && activityStatusWithoutDraft.length)
    ) {
      try {
        const { data } = await doRequest(requestData)

        response = data
      } catch (error) {
        console.error(error)

        return
      }
    } else {
      response = { cantElements: 0, data: [] }
    }

    const { data: parsedActivities } = wrapData({
      currentData: params.page === INIT_PAGE ? [] : activities,
      newData: response.data,
    })

    const newPaginationData = {
      ...paginationData.current,
      page: response.page,
      totalPages: response.totalPages,
    }

    paginationData.current = newPaginationData

    return parsedActivities
  }

  const nextPage = async () => {
    if (
      isLoading ||
      paginationData.current.page === paginationData.current.totalPages
    ) {
      return
    }

    const newPaginationData = {
      ...paginationData.current,
      page: paginationData.current.page + 1,
    }

    paginationData.current = newPaginationData

    initFetchData()
  }

  return {
    isLoading,
    activities,
    nextPage,
  }
}
