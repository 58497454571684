export default {
  TEXT_1: 'UCROP.IT Need location permission',
  TEXT_2: 'The allowed extension is .kmz',
  TEXT_3:
    'The allowed extensions would be .doc,.docx,.pdf,.png,.jpg,.zip,.xlsx,.xls',
  TEXT_4: 'File extension is not allowed',
  TEXT_5: 'Accept',
  TEXT_6: 'Add evidence',
  TEXT_7: 'Looking for georeference...',
  TEXT_8: 'Please dont leave this screen',
  TEXT_9: 'The file you are trying to upload does not have the location data.',
  TEXT_10: 'No camera permissions',
  TEXT_11: 'Please go to settings and activate manually',
  TEXT_12: 'Cancel',
  TEXT_13: 'Allow',
  TEXT_14: 'Gallery',
  TEXT_15: 'Documents',
  TEXT_16: 'Camera',
  TEXT_17: 'Cancel',
  TEXT_18: 'Try uploading another file',
  TEXT_19:
    'The file you are trying to upload is not a KMZ or it has been damaged.',
  ERROR_SIZE: 'The file exceeds the allowed size, which is 100 MB.',
}
