import React, { useState, createContext, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import useNetwork from '@utils/network'
import { useOfflineCrops } from '@offline/queries/crops'
import { useOfflineCommon } from '@offline/queries/common'
import { ROL_VERIFIER } from '@constants/roles'
import {
  ITEMS_PER_PAGE,
  INITIAL_PAGE,
} from '@modules/crops/screens/CropList/v1/utils'
import { useSelector } from 'react-redux'
import { useAuth } from '@common/hooks'

const INITIAL_CROPS = []
const INITIAL_PAGINATION = null

export const CropContext = createContext({})

export const CropProvider = ({ children }) => {
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { config } = useAuth()

  const { doRequest, loading } = useNetwork()
  const { getPagedCropsOffline } = useOfflineCrops()
  const { showIndividuals } = useOfflineCommon()

  const [data, setDrafts] = useState(INITIAL_CROPS)
  const [synchronizeList, setSynchronizedList] = useState([])
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [currentCrop, setCurrentCrop] = useState({})
  const [hasCropOffline, setHasCropOffline] = useState()
  const [currentCollaborator, setCurrentCollaborator] = useState(null)
  const [currentCropId, setCurrentCropId] = useState('')
  const [pagination, setPagination] = useState(INITIAL_PAGINATION)
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    fetchCurrentCrop(currentCropId)
  }, [config?.companySelected?._id, currentCropId])

  /**
   *
   * @param {object} params
   * @param {boolean} [forceOffline=false]
   *
   * @return {array}
   */
  async function fetchCrops(params, forceOffline = false) {
    if (isLoadingMore) {
      return
    }

    setIsLoadingMore(true)

    try {
      let crops = []
      let meta = {}
      const page = params?.page ?? currentPage
      const limit = params?.limit ?? ITEMS_PER_PAGE

      if (isConnected && !forceOffline) {
        delete params.companySelectedIdentifier

        const response = await doRequest({
          method: 'GET',
          url: 'crops',
          params: {
            ...params,
            page,
            limit,
          },
          version: 'v2',
          displayAlert: false,
        })
        crops = response.data.data
        meta = response.data.meta.length
          ? response.data.meta[0]
          : {
              totalItems: 0,
              currentPage: INITIAL_PAGE,
              itemsPerPage: ITEMS_PER_PAGE,
              totalPages: 0,
            }
      } else {
        const { identifier, companySelectedIdentifier } = params
        const response = await getPagedCropsOffline({
          page,
          identifier,
          companySelectedIdentifier,
        })
        crops = response.items
        meta = response.meta
      }

      crops = page === INITIAL_PAGE ? crops : [...data, ...crops]

      setDrafts(crops)
      setPagination(meta)
      return crops
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoadingMore(false)
    }
  }

  async function getSynchronizeServices(id) {
    try {
      const response = await doRequest({
        method: 'GET',
        url: `crops/${id}`,
      })
      setSynchronizedList(response.data.synchronizedList)
    } catch (err) {
      console.warn(err)
    }
  }

  async function fetchCurrentCrop(id) {
    try {
      if (!id || !config.companySelected?._id) {
        return
      }

      let responseData

      if (isConnected) {
        const response = await doRequest({
          method: 'GET',
          url: `crops/${id}`,
          version: 'v2',
          params: {
            companyId: config?.companySelected?._id,
          },
          displayAlert: false,
        })

        responseData = response ? response.data : []
      } else {
        responseData = await showIndividuals('crops', id)
      }

      setCurrentCrop(responseData)
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   * FETCH CROP
   *
   * @param {*} cropId
   */
  const fetchCrop = async (cropId) => {
    let data
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `crops/${cropId}`,
        displayAlert: false,
      })
      data = response.data
    }
    return data
  }

  /**
   * SELECT CROP
   *
   * @param {*} crop
   */
  const selectCrop = (crop) => {
    setCurrentCrop(crop)
  }

  const isVerifier = () => {
    return currentCollaborator?.type === ROL_VERIFIER
  }

  return (
    <CropContext.Provider
      value={{
        fetchCrops,
        fetchCrop,
        data,
        setDrafts,
        loadingCrop: loading,
        isDownloaded,
        setIsDownloaded,
        getSynchronizeServices,
        synchronizeList,
        setCurrentCrop,
        currentCrop,
        selectCrop,
        hasCropOffline,
        setHasCropOffline,
        currentCollaborator,
        setCurrentCollaborator,
        isVerifier,
        setCurrentCropId,
        currentPage,
        setCurrentPage,
        pagination,
        isLoadingMore,
      }}
    >
      {children}
    </CropContext.Provider>
  )
}
CropProvider.propTypes = {
  children: PropTypes.object,
}
