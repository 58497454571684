import { View, StyleSheet, FlatList, Platform } from 'react-native'
import { Snackbar } from 'react-native-paper'

import { ActivityIndicator } from '@modules/common/components'
import { FabButton } from '@common/components'
import { EmptyStateCropStory, HeaderCropsList, CropItem } from './components'

import { ORDER_MENU_ENUM } from '@modules/common/utils'
import userTypes from '@constants/userTypes'

import { orderMenuOptions } from './utils'
import { useCropList } from './hooks'
import PendingCard from './components/PendingCard'
import Home from '@modules/home/screens/HomeWithoutCompany'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { CommonIcon } from '@modules/common/components'

const CropList = ({ navigation }) => {
  const {
    crops,
    countrySupplies,
    visibleErrorSupplies,
    setVisibleErrorSupplies,
    showDeleteDialog,
    setShowDeleteDialog,
    companyAllow,
    nextPage,
    onPressCrop,
    onPressCropMenu,
    handleDeleteCrop,
    handleOrderChange,
    subTitleList,
    getCropStatus,
    getCropType,
    getIsCropOffline,
    getIsCropDownloaded,
    orderCropsBy,
    user,
    config,
    isLoadingMore,
    t,
    isConnected,
    hasPermissionCreate,
    isPendingAuthorization,
  } = useCropList()

  const renderItem = ({ item: crop }) => {
    const subtitle = subTitleList({
      dateCrop: crop.dateCrop,
      dateHarvest: crop.dateHarvest,
      status: crop.status,
    })

    const status = getCropStatus(crop)

    const type = getCropType(crop.members)

    const canWrite =
      type !== userTypes.MARKETER.key && type !== userTypes.PROVIDER.key

    const isCropOffline = getIsCropOffline(crop.members)

    const downloaded = getIsCropDownloaded(isCropOffline)

    return (
      <CropItem
        name={crop.name}
        subtitle={subtitle}
        status={status}
        onPress={() => onPressCrop(crop)}
        disabled={!companyAllow}
        crop={crop}
        onPressCropMenu={onPressCropMenu}
        canWrite={canWrite}
        isCropOffline={isCropOffline}
        downloaded={downloaded}
      />
    )
  }

  const renderFooter = () =>
    isLoadingMore && (
      <ActivityIndicator
        size='large'
        containerStyle={styles.activityIndicator}
      />
    )

  return (
    <View style={styles.container}>
      {isPendingAuthorization ? (
        <PendingCard username={user?.firstName} />
      ) : !config?.companySelected ? (
        <Home navigation={navigation} />
      ) : (
        <FlatList
          data={crops}
          extraData={crops}
          ListHeaderComponent={
            <HeaderCropsList
              show={
                Boolean(crops.length) ||
                orderCropsBy?.key === ORDER_MENU_ENUM.ENABLED_OFFLINE
              }
              handleOrderChange={handleOrderChange}
              orderMenuOptions={orderMenuOptions}
              orderSelectedOption={orderCropsBy}
              username={user?.firstName}
            />
          }
          renderItem={renderItem}
          keyExtractor={(crop, index) => index}
          ListEmptyComponent={
            <EmptyStateCropStory
              show={
                !(
                  Boolean(crops.length) ||
                  orderCropsBy?.key === ORDER_MENU_ENUM.ENABLED_OFFLINE ||
                  isLoadingMore
                )
              }
            />
          }
          showsVerticalScrollIndicator={Platform.OS === 'web'}
          onEndReached={nextPage}
          onEndReachedThreshold={0.5}
          ListFooterComponent={renderFooter}
        />
      )}

      <ModalConfirm
        visible={showDeleteDialog}
        onConfirm={handleDeleteCrop}
        onClose={() => setShowDeleteDialog(false)}
        title={t('VIEWS').CROP.TEXT_14}
        contentText={t('VIEWS').CROP.TEXT_17}
        confirmText={t('VIEWS').CROP.TEXT_18}
        cancelText={t('VIEWS').CROP.TEXT_19}
      />

      <Snackbar
        visible={visibleErrorSupplies}
        onDismiss={() => setVisibleErrorSupplies(false)}
        action={{
          label: t('VIEWS').CROP.TEXT_34,
          onPress: () => setVisibleErrorSupplies(false),
        }}
        theme={{ colors: { accent: '#C8E6C9' } }}
      >
        {t('VIEWS.CROP.TEXT_32', { country: countrySupplies })}
      </Snackbar>

      {isConnected &&
        hasPermissionCreate &&
        !isPendingAuthorization &&
        config?.companySelected && (
          <FabButton
            icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
            onPress={() => navigation.navigate('CropCreate', {})}
          />
        )}
      {/* <VerifyPin isModalVisible /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  activityIndicator: {
    flex: 1,
    marginBottom: 20,
    marginTop: 10,
  },
})

export default CropList
