import { uniq } from 'lodash'
import { useSelector } from 'react-redux'

import useNetwork from '@utils/network'
import { GlobalStateInterface } from '@store/interfaces'

const SELECT_CROP_BY_ID_QUERY = 'SELECT * FROM individuals_crops WHERE _id = ?;'
const UPDATE_CROP_QUERY = 'UPDATE individuals_crops SET data = ? WHERE _id = ?;'

export const useOfflineCommon = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )

  const { doRequest } = useNetwork()

  const createTable = async (tableName: string) => {
    await database.execAsync(
      `CREATE TABLE IF NOT EXISTS ${tableName} (id integer primary key not null, _id text, data text)`
    )
  }

  const initOfflineCommon = async () => {
    if (!database) {
      return
    }

    await Promise.all([
      createTable('individuals_crops'),
      createTable('individuals_activities'),
    ])

    return
  }

  const storeIndividual = async (name: string, id: string, data: any) => {
    if (!database) {
      return
    }

    await database.runAsync(
      `INSERT INTO individuals_${name} (_id, data) values (?,?)`,
      [id, JSON.stringify(data)]
    )

    return
  }

  const storeAtIndividuals = async (
    name: any,
    id: string,
    params: any = {}
  ) => {
    if (!database) {
      return
    }

    const version = name === 'crops' ? 'v2' : 'v1'

    const response = await doRequest({
      method: 'GET',
      url: `${name}/${id}`,
      version,
      params,
      displayAlert: false,
    })

    const dataToInsert = response.data

    dataToInsert.establishments = dataToInsert.establishments.map(
      (farm: any) => ({
        ...farm,
        lots: farm.lots.map((field: any) => ({
          ...field,
          area: null,
          geometryData: null,
          wkt: null,
        })),
      })
    )

    dataToInsert.toMake = dataToInsert.toMake.map((activity: any) => ({
      ...activity,
      establishments: activity.establishments.map((farm: any) => ({
        ...farm,
        lots: farm.lots.map((field: any) => ({
          ...field,
          area: null,
          geometryData: null,
          wkt: null,
        })),
      })),
    }))

    dataToInsert.done = dataToInsert.done.map((activity: any) => ({
      ...activity,
      establishments: activity.establishments.map((farm: any) => ({
        ...farm,
        lots: farm.lots.map((field: any) => ({
          ...field,
          area: null,
          geometryData: null,
          wkt: null,
        })),
      })),
    }))

    dataToInsert.finished = dataToInsert.finished.map((activity: any) => ({
      ...activity,
      establishments: activity.establishments.map((farm: any) => ({
        ...farm,
        lots: farm.lots.map((field: any) => ({
          ...field,
          area: null,
          geometryData: null,
          wkt: null,
        })),
      })),
    }))

    const result = await database.getFirstAsync(
      `SELECT id, _id FROM individuals_${name} where _id = '${id}'`
    )

    if (result) {
      return
    }

    await database.runAsync(
      `INSERT INTO individuals_${name} (_id, data) values (?,?)`,
      [id, JSON.stringify(dataToInsert)]
    )

    if (name === 'crops') {
      await storeActivities(response.data)
    }
  }

  const storeActivities = async (crop: any) => {
    const pending = crop.pending.map((element: any) => element._id)
    const toMake = crop.toMake.map((element: any) => element._id)
    const done = crop.done.map((element: any) => element._id)
    const finished = crop.finished.map((element: any) => element._id)
    const ids = uniq([...pending, ...toMake, ...done, ...finished])

    const response = await doRequest({
      method: 'GET',
      url: `activities?ids=${JSON.stringify(ids)}`,
      displayAlert: false,
    })

    const activities = response.data

    if (activities.length > 0) {
      await Promise.all(
        activities.map(async (element: any) => {
          await database.runAsync(
            'INSERT INTO individuals_activities (_id, data) values (?, ?)',
            [element._id, JSON.stringify(element)]
          )
        })
      )
    }

    return
  }

  const storeAchievement = async (data: any, activity: any) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * FROM individuals_activities WHERE _id = '${activity}';`
    )

    if (!result) {
      return
    }

    const activityData = JSON.parse(result.data)

    activityData.achievements = activityData.achievements
      ? [...activityData.achievements, data]
      : [data]

    await database.runAsync(
      'UPDATE individuals_activities SET data = ? where _id = ?;',
      [JSON.stringify(activityData), activity]
    )

    return
  }

  const showIndividuals = async (name: string, id: string) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * FROM individuals_${name} WHERE _id = ?`,
      [id]
    )

    return JSON.parse(result?.data)
  }

  const findOneIndividual = async (name: string) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * FROM individuals_${name} LIMIT 1;`
    )

    if (!result) {
      return
    }

    return JSON.parse(result.data)
  }

  const deleteIndividuals = async (name: string, id: string) => {
    if (!database) {
      return
    }

    await database.runAsync(
      `DELETE FROM individuals_${name} WHERE _id = '${id}'`
    )

    return
  }

  const deleteIndividualCrop = async (crop: any) => {
    if (!database) {
      return
    }

    let count = 0

    const pending = crop.pending
      .filter((element: any) => element)
      .map((element: any) => element._id)
    const toMake = crop.toMake
      .filter((element: any) => element)
      .map((element: any) => element._id)
    const done = crop.done
      .filter((element: any) => element)
      .map((element: any) => element._id)
    const finished = crop.finished
      .filter((element: any) => element)
      .map((element: any) => element._id)

    const ids = uniq([...pending, ...toMake, ...done, ...finished])

    for (const id of ids) {
      countIndividuals('activities', id).then((count) => {
        if (count > 0) {
          deleteIndividuals('activities', id)
        }
      })
    }

    count = await countIndividuals('crops', crop._id)

    if (count > 0) {
      await deleteIndividuals('crops', crop._id)
    }
  }

  const countIndividuals = async (
    name: string,
    id: string
  ): Promise<number> => {
    if (!database) {
      return 0
    }

    const result = await database.getFirstAsync(
      `SELECT COUNT(id) AS count FROM individuals_${name} WHERE _id = '${id}'`
    )

    return result.count
  }

  const deleteAllIndividuals = async (name: string) => {
    if (!database) {
      return
    }

    await database.runAsync(`DELETE FROM individuals_${name}`)

    return
  }

  const updateFarmsInCrop = async ({ cropId, farms }: any) => {
    if (!database) {
      return
    }

    const crop = await findCropById({ cropId })

    const dataToUpdate = parseDataToUpdateFarmsInCrop({ crop, farms })

    await updateCrop(dataToUpdate)

    return
  }

  const findCropById = async ({ cropId }: any) => {
    const result = await database.getFirstAsync(SELECT_CROP_BY_ID_QUERY, [
      cropId,
    ])

    if (!result) {
      return
    }

    return JSON.parse(result.data)
  }

  const parseDataToUpdateFarmsInCrop = ({ crop, farms }: any) => {
    const newDraft = {
      ...crop,
      establishments: farms,
    }

    const additionalData = JSON.stringify(newDraft)

    const dataToUpdate = [additionalData, crop._id]

    return dataToUpdate
  }

  const updateCrop = async (dataToUpdate: any) => {
    await database.runAsync(UPDATE_CROP_QUERY, dataToUpdate)
  }

  return {
    initOfflineCommon,
    storeAtIndividuals,
    deleteIndividuals,
    showIndividuals,
    deleteAllIndividuals,
    storeIndividual,
    storeAchievement,
    countIndividuals,
    deleteIndividualCrop,
    findOneIndividual,
    updateFarmsInCrop,
  }
}
