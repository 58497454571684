import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import moment from 'moment'
import { SelectorContext } from '@contextState/selectors'
import { CommonContext } from '@contextState/common'
import { filterSubActivityTypesByType } from '@utils/common'
import { round } from 'lodash'
import { LanguageContext } from '@contextState/language'
import { addDateFifteenDays } from '@utils/date'
import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import InputText from '@components/inputs/InputText'
import InputDate from '@components/inputs/InputDate'
import { black, blackMediumEmphasis } from '@styles/palette'
import { formatDateMoment } from '@utils/date'
import { MAX_CONSIDERATION_LENGTH } from '@utils/constants'
import { truncateSurface } from '@common/utils/truncateSurface'
import { useSelector } from 'react-redux'

function CommonForm({
  route,
  navigation,
  setFieldValue,
  values,
  setSurface,
  activityType,
  areaUnit,
  setFieldTouched,
  touched,
  errors,
}) {
  const {
    lotSelector,
    lotSelectorTotalSurface,
    supplySelector,
    setSupplySelector,
  } = useContext(SelectorContext)
  const { subTypeActivities } = useSelector(
    (state) => state.activityTypesReducer
  )
  const [filteredSubTypes, setFilteredSubTypes] = useState([])
  const [tag, setTag] = useState('')
  const { t, i18n } = useContext(LanguageContext)

  useEffect(() => {
    if (route) {
      setTag(route.params.tag)
    }
    let filteredSubTypes = []
    if (subTypeActivities.length) {
      filteredSubTypes = filterSubActivityTypesByType(
        subTypeActivities,
        activityType.value
      )
    }
    setFilteredSubTypes(filteredSubTypes)
  }, [activityType])

  useEffect(() => {
    if (lotSelector.length) {
      setFieldValue('lots', {
        value: lotSelector,
        label: `${lotSelector.length} ${t(
          'COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_1'
        )}`,
      })
      setFieldValue('surface', String(lotSelectorTotalSurface))
    } else {
      setFieldValue('lots', {
        value: '',
        label: '',
      })
      setFieldValue('surface', '')
    }
  }, [lotSelector])

  useEffect(() => {
    const surface = values.surface ? Number(values.surface) : 0
    if (supplySelector.length && surface) {
      const UpdateQuantitySurface = supplySelector.map((supply) => {
        return {
          ...supply,
          quantity: round(Number(supply.total) / Number(surface), 6),
          surface,
        }
      })
      setSupplySelector(UpdateQuantitySurface)
    }
    setSurface(surface)
  }, [values.surface])

  const onPressLotsInput = () => {
    navigation.navigate('LotsSelectorModal', {
      id: route.params.id,
      type: 'crops',
    })
  }

  return (
    <View style={styles.content}>
      <InputSelectDefault
        id='subTypeActivity'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('subTypeActivity', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('subTypeActivity'))}
        value={filteredSubTypes.length ? values.subTypeActivity : null}
        label={`${t('COMPONENTS').ACTIVITIES.COMMON_FORM.TEXT_10} ${
          t('CONSTANTS').ACTIVITY_TYPES[tag]
        }`}
        placeholder={`${t('COMPONENTS').ACTIVITIES.COMMON_FORM.TEXT_10} ${
          t('CONSTANTS').ACTIVITY_TYPES[tag]
        }`}
        options={filteredSubTypes}
        touched={touched.country}
        error={errors?.subTypeActivity?.value}
      />

      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={20}
            color={black}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_2.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_2.LABEL')}`}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputDate
        id='dateStart'
        onChangeText={(value) => {
          setFieldValue('dateStart', value)
          const dateEnd = addDateFifteenDays(value.value)
          setFieldValue('dateEnd', {
            value: dateEnd,
            label: formatDateMoment(dateEnd, 'DD/MMM/YYYY', i18n.locale, true),
          })
        }}
        value={values.dateStart}
        label={t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_5')}
        placeholder={t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_5')}
        disablePast={false}
        touched={touched.dateStart}
        error={errors?.dateStart?.value}
        onBlur={() => setTimeout(() => setFieldTouched('dateStart'))}
      />

      <InputDate
        id='dateEnd'
        onChangeText={(value) => {
          setTimeout(() => setFieldValue('dateEnd', value))
        }}
        value={values.dateEnd}
        label={t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_6')}
        placeholder={t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_6')}
        disablePast={false}
        minDate={
          values.dateStart.value
            ? moment(values.dateStart.value).add(1, 'd').toDate()
            : undefined
        }
        touched={touched.dateEnd}
        error={errors?.dateEnd?.value}
        onBlur={() => setTimeout(() => setFieldTouched('dateEnd'))}
      />

      <InputText
        id='considerations'
        maxLength={MAX_CONSIDERATION_LENGTH}
        onChangeText={(value) => {
          setTimeout(() => setFieldValue('considerations', value))
        }}
        containerStyle={styles.containerInput}
        placeholder={t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_12')}
        label={t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_11')}
        multiline={true}
        touched={touched.considerations}
        error={errors.considerations}
      />

      <Text style={[styles.observation, styles.marginTop]}>
        {`${t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_13', {
          considerationsLength:
            MAX_CONSIDERATION_LENGTH - values.considerations.length,
        })}`}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
  },
  containerInput: {
    marginVertical: 1,
  },
  observation: {
    fontSize: 10,
    color: blackMediumEmphasis,
    marginTop: -15,
    marginBottom: 20,
  },
})

export default CommonForm
