import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { ObjectId } from '@utils/common'
import { GlobalStateInterface } from '@store/interfaces'

export const CREATE_TABLE_QUERY_DRAFTS =
  'CREATE TABLE IF NOT EXISTS drafts (id integer primary key not null, _id text, crop text, activity text, version integer, isSynchronized bool, additionalData text, createdAt text, updatedAt text, deletedAt text, draftGroupId text);'
const SELECT_COLUMNS_QUERY = 'PRAGMA table_info(drafts);'
const CREATE_COLUMN_QUERY = 'ALTER TABLE drafts ADD column_name column_type'
const DROP_TABLE_QUERY = 'DROP TABLE IF EXISTS drafts;'
const SELECT_BY_CROP_QUERY =
  'SELECT * FROM drafts WHERE crop = ? AND deletedAt IS NULL ORDER BY updatedAt DESC;'
const SELECT_BY_DRAFT_GROUP_ID_QUERY =
  'SELECT * FROM drafts WHERE draftGroupId = ? AND deletedAt IS NULL ORDER BY updatedAt DESC;'
const SELECT_BY_ACTIVITY_ORDERED_BY_VERSION_QUERY =
  'SELECT * FROM drafts WHERE activity = ? AND deletedAt IS NULL ORDER BY updatedAt DESC, version DESC;'
const SELECT_BY_ID_QUERY =
  'SELECT * FROM drafts WHERE _id = ? AND deletedAt IS NULL;'
const INSERT_QUERY =
  'INSERT INTO drafts (_id, crop, activity, version, isSynchronized, additionalData, createdAt, updatedAt, deletedAt, draftGroupId) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?);'
const UPDATE_QUERY =
  'UPDATE drafts SET isSynchronized = ?, additionalData = ?, updatedAt = ? WHERE _id = ?;'
const UPDATE_DATE_DRAFTS_QUERY =
  'UPDATE drafts SET updatedAt = ? WHERE draftGroupId = ?;'
const DELETE_BY_ID_QUERY = 'DELETE FROM drafts WHERE _id = ?;'
const SELECT_BY_UNSYNCHRONIZED_QUERY =
  'SELECT * FROM drafts WHERE isSynchronized = 0;'
const DELETE_ALL_QUERY = 'DELETE FROM drafts;'
const columns = [
  {
    name: '_id',
    type: 'text',
  },
  {
    name: 'crop',
    type: 'text',
  },
  {
    name: 'activity',
    type: 'text',
  },
  {
    name: 'version',
    type: 'integer',
  },
  {
    name: 'isSynchronized',
    type: 'bool',
  },
  {
    name: 'additionalData',
    type: 'text',
  },
  {
    name: 'createdAt',
    type: 'text',
  },
  {
    name: 'updatedAt',
    type: 'text',
  },
  {
    name: 'deletedAt',
    type: 'text',
  },
  {
    name: 'draftGroupId',
    type: 'text',
  },
]

export const useOfflineDrafts = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )

  const initOfflineDraft = async () => {
    if (!database) {
      return
    }

    await database.execAsync(CREATE_TABLE_QUERY_DRAFTS)

    const results = await database.getAllAsync(SELECT_COLUMNS_QUERY)

    const nonexistentColumns = []

    for (const column of columns) {
      if (!results.find((element: any) => element.name === column.name)) {
        nonexistentColumns.push(column)
      }
    }

    for (const nonexistentColumn of nonexistentColumns) {
      await database.execAsync(
        CREATE_COLUMN_QUERY.replace(
          'column_name',
          nonexistentColumn.name
        ).replace('column_type', nonexistentColumn.type)
      )
    }

    return
  }

  const dropDraftTable = async () => {
    if (!database) {
      return
    }

    await database.execAsync(DROP_TABLE_QUERY)
  }

  const findDraftsByCropIdOffline = async (cropId: string) => {
    if (!database) {
      return []
    }

    const drafts = await findDraftsByCropId(cropId)

    return drafts
  }

  const findDraftsByCropId = async (cropId: string) => {
    const results = await database.getAllAsync(SELECT_BY_CROP_QUERY, [cropId])

    return results.map((element: any) => JSON.parse(element.additionalData))
  }

  const findDraftsByDraftGroupIdOffline = async (draftGroupId: string) => {
    if (!database) {
      return []
    }

    const drafts = await findDraftsByDraftGroupId(draftGroupId)

    return drafts
  }

  const findDraftsByDraftGroupId = async (draftGroupId: string) => {
    const results = await database.getAllAsync(SELECT_BY_DRAFT_GROUP_ID_QUERY, [
      draftGroupId,
    ])

    return results.map((element: any) => JSON.parse(element.additionalData))
  }

  const findDraftsByActivityIdOffline = async (activityId: string) => {
    if (!database) {
      return []
    }

    const drafts = await findDraftsByActivityId(activityId)

    return drafts
  }

  const findDraftsByActivityId = async (activityId: string) => {
    const results = await database.getAllAsync(
      SELECT_BY_ACTIVITY_ORDERED_BY_VERSION_QUERY,
      [activityId]
    )

    return results.map((element: any) => JSON.parse(element.additionalData))
  }

  const findDraftByIdOffline = async (draftId: string) => {
    if (!database) {
      return
    }

    const draft = await findDraftById(draftId)

    return draft
  }

  const findDraftById = async (draftId: string) => {
    const result = await database.getFirstAsync(SELECT_BY_ID_QUERY, [draftId])

    if (!result) {
      return
    }

    return JSON.parse(result.additionalData)
  }

  const insertDraftOffline = async (data: any, user: any) => {
    if (!database) {
      return
    }

    const dataToInsert = parseDataToInsert(data, user)

    await insertDraft(dataToInsert)

    return
  }

  const parseDataToInsert = (data: any, user: any) => {
    const currentDate = new Date().toISOString()

    const draftId = data.id || data._id || ObjectId()

    const newDraft = {
      ...data,
      id: draftId,
      _id: draftId,
      createdByUserId: user._id,
      createdByUsername: user.name || data.createdByUsername,
      updatedByUserId: user._id,
      updatedByUsername: user.name || data.updatedByUsername,
      draftGroupId: data.draftGroupId || uuidv4(),
      isSynchronized: data.isSynchronized ? 1 : 0,
      createdAt: data.createdAt
        ? new Date(data.createdAt).toISOString()
        : currentDate,
      updatedAt: data.updatedAt
        ? new Date(data.updatedAt).toISOString()
        : currentDate,
      dateAchievement: data.dateAchievement
        ? new Date(data.dateAchievement).toISOString()
        : currentDate,
      dateHarvest: data.dateHarvest
        ? new Date(data.dateHarvest).toISOString()
        : undefined,
      dateObservation: data.dateObservation
        ? new Date(data.dateObservation).toISOString()
        : undefined,
      dateEstimatedHarvest: data.dateEstimatedHarvest
        ? new Date(data.dateEstimatedHarvest).toISOString()
        : undefined,
      evidences: data.evidences?.length ? data.evidences : [],
      signers: data.signers?.length ? data.signers : [],
      storages: data.storages?.length ? data.storages : [],
      deletedAt: null,
      version: data.version || 1,
      observations: data.observations
        ? data.observations?.map((observation: any) => ({
            ...observation,
            createdAt: new Date(observation.createdAt).toISOString(),
          }))
        : [],
    }

    const additionalData = JSON.stringify(newDraft)

    const dataToInsert = [
      newDraft._id,
      newDraft.crop,
      newDraft.activity || null,
      1,
      newDraft.isSynchronized,
      additionalData,
      newDraft.createdAt,
      newDraft.updatedAt,
      null,
      newDraft.draftGroupId,
    ]

    return dataToInsert
  }

  const insertDraft = async (dataToInsert: any) => {
    await database.runAsync(INSERT_QUERY, dataToInsert)

    return
  }

  const updateDraftOffLine = async (draftId: string, data: any, user: any) => {
    if (!database) {
      return
    }

    const draft = await findDraftById(draftId)

    const { dataToUpdate, dataUpdateVersions } = parseDataToUpdate(
      draftId,
      draft,
      data,
      user
    )

    await updateDraft(dataToUpdate, dataUpdateVersions)

    return
  }

  const parseDataToUpdate = (
    draftId: string,
    draft: any,
    data: any,
    user: any
  ) => {
    const currentDate = new Date().toISOString()

    const newDraft = {
      ...draft,
      ...data,
      isRejected: false,
      updatedByUserId: user._id,
      updatedByUsername: user.name,
      isSynchronized: 0,
      updatedAt: currentDate,
    }
    const additionalData = JSON.stringify(newDraft)

    const dataToUpdate = [
      newDraft.isSynchronized,
      additionalData,
      newDraft.updatedAt,
      draftId,
    ]

    const dataUpdateVersions = [currentDate, newDraft.draftGroupId]

    return { dataToUpdate, dataUpdateVersions }
  }

  const updateDraft = async (dataToUpdate: any, dataUpdateVersions: any) => {
    const queries = [
      {
        query: UPDATE_QUERY,
        params: dataToUpdate,
      },
      {
        query: UPDATE_DATE_DRAFTS_QUERY,
        params: dataUpdateVersions,
      },
    ]

    return Promise.all(
      queries.map(async (elementQuery: any) => {
        const { query, params } = elementQuery

        await database.runAsync(query, params)
      })
    )
  }

  const deleteDraftByIdOffline = async (draftId: string) => {
    if (!database) {
      return
    }

    await deleteDraftById(draftId)

    return
  }

  const deleteDraftById = async (draftId: string) => {
    await database.runAsync(DELETE_BY_ID_QUERY, [draftId])
  }

  const findDraftsByUnsynchronizedOffline = async () => {
    if (!database) {
      return []
    }

    const drafts = await findDraftsByUnsynchronized()

    return drafts
  }

  const findDraftsByUnsynchronized = async () => {
    const results = await database.getAllAsync(SELECT_BY_UNSYNCHRONIZED_QUERY)

    return results.map((element: any) => JSON.parse(element.additionalData))
  }

  const deleteAllDraftOffline = async () => {
    if (!database) {
      return
    }

    await deleteAllDraft()

    return
  }

  const deleteAllDraft = async () => {
    await database.runAsync(DELETE_ALL_QUERY)
  }

  return {
    initOfflineDraft,
    dropDraftTable,
    findDraftsByCropIdOffline,
    findDraftsByDraftGroupIdOffline,
    findDraftsByActivityIdOffline,
    findDraftByIdOffline,
    insertDraftOffline,
    updateDraftOffLine,
    deleteDraftByIdOffline,
    findDraftsByUnsynchronizedOffline,
    deleteAllDraftOffline,
  }
}
