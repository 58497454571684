import { useContext } from 'react'
import { t } from 'i18n-js'

import { useOfflineQueue } from '@offline/queries/queue'
import useNetwork from '@utils/network'
import { CommonContext } from '@contextState/common'
import { SYNC_ERROR } from '@modules/common/utils'

export const useSync = () => {
  const { showQueue, changeSyncedFlag } = useOfflineQueue()
  const { doRequest } = useNetwork()
  const { displayToggleModalNotification } = useContext(CommonContext)

  const doSync = async () => {
    const queue = await showQueue()

    const requests = queue.map(async (queue) => {
      const { method, url, needsSerialize, files } = queue.params

      if (needsSerialize) {
        const formData = new FormData()
        formData.append('data', JSON.stringify(queue?.data))

        if (files) {
          files
            .filter((el) => !el.persisted)
            .forEach((el) => {
              formData.append('files', el.file)
            })
        }

        try {
          await doRequest({
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method,
            url,
            data: formData,
            displayAlert: false,
          })
        } catch (err) {
          if (err.code === SYNC_ERROR.INVALID_LOTS) {
            displayToggleModalNotification({
              text: t('VIEWS').OFFLINE_QUEUE.INVALID_LOTS,
              duration: 15000,
            })
          }
        }
      } else {
        await doRequest({
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          method,
          url,
          data: queue?.data,
        })
      }

      return changeSyncedFlag(queue.id)
    })

    await Promise.all(requests).finally(() => {
      setTimeout(() => alert(t('CONTEXT_STATE').CONNECTION.TEXT_1), 2000)
    })
  }

  return { doSync }
}
