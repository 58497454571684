import { makeRequest } from '@common/services'
import { useOfflineTypes } from '@offline/queries/types'
import { GlobalStateInterface } from '@store/interfaces'
import { setUnitTypes } from '@store/slices'
import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

export const useUnitTypes = () => {
  const dispatch = useDispatch()
  const { storeType, getTypes } = useOfflineTypes()
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )
  const fetchUnitTypes = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'commons/unitypes' })

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('unitypes', data)
      }
    } else {
      data = await getTypes('unitypes')
    }
    const unitTypes = data.map((unitType: any) => ({
      ...unitType,
      label: `${unitType.key} (${unitType.keyLabel})`,
      value: unitType._id,
      key: unitType.key,
      unitMeasureSystem: unitType.unitMeasureSystem,
    }))
    dispatch(setUnitTypes(unitTypes))
  }
  return {
    fetchUnitTypes,
  }
}
