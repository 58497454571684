import { useContext, useEffect, useState } from 'react'
import { getUnitsLongitude } from '@common/utils'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import { filterSubActivityTypesByType } from '@utils/common'
import { useAuth } from '@common/hooks'
import { useSelector } from 'react-redux'
import { GlobalStateInterface } from '@store/interfaces'

export const useIrrigationForm = ({ activityType }: any) => {
  const { t, i18n } = useContext(LanguageContext)
  const { subTypeActivities } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )
  const { config } = useAuth()
  const [filteredSubTypes, setFilteredSubTypes] = useState([])
  const unitMeasureSystem = config.companySelected?.unitMeasureSystem
  // @ts-ignore
  const unitsLongitude = getUnitsLongitude(unitMeasureSystem, i18n?.locale)
  const { irrigationPowerSources, irrigationWaterSources } = useSelector(
    (state: GlobalStateInterface) => state.irrigationDataReducer
  )
  useEffect(() => {
    let filteredSubTypes = []

    if (subTypeActivities.length) {
      filteredSubTypes = filterSubActivityTypesByType(
        subTypeActivities,
        activityType?.value
      )
    }
    setFilteredSubTypes(filteredSubTypes)
  }, [subTypeActivities, activityType])

  return {
    filteredSubTypes,
    unitsLongitude,
    irrigationPowerSources,
    irrigationWaterSources,
    t,
  }
}
