import React, { useContext } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Paragraph } from 'react-native-paper'
import { PropTypes } from 'prop-types'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { APP_BUILD, APP_VERSION } from '@appVersion'
import { signOut } from '@services/firebase'
import { useOfflineUser } from '@offline/queries/user'
import { useOfflineQueue } from '@offline/queries/queue'
import { useOfflineDrafts } from '@offline/queries/drafts'
import { useOfflineCrops } from '@offline/queries/crops'
import { useOfflineSupplies } from '@offline/queries/supplies'
import { useOfflineEvidenceDrafts } from '@offline/queries/evidenceDrafts'
import { LanguageContext } from '@contextState/language'
import useModal from '@hooks/useModal'
import {
  secondary600,
  blackHighEmphasis,
  grayInput,
  blackInactive,
  white,
} from '@styles/palette'
import { awsSignOut } from '@services/amplify'

import ItemMenu from './components/ItemMenu'
import { ModalTerms } from '@modules/common/components/modals/ModalTerms'
import { useFetchTerms } from '@modules/common/hooks'
import { activitiesActions } from '@store/actions/activities'
import { removePin, setConfig, setIsAuth, setUser } from '@store/slices'
import ModalConfirm from '@components/common/v1/ModalConfirm'

const SettingsScreen = ({ navigation }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useContext(LanguageContext)
  const { isConnected } = useSelector((state) => state.connectionReducer)

  const { isModalVisible, toggleModal } = useModal()
  const { deleteUser } = useOfflineUser()
  const { dropAllQueue } = useOfflineQueue()
  const { deleteAllDraftOffline } = useOfflineDrafts()
  const { deleteAllCropOffline } = useOfflineCrops()
  const { resetSupplies } = useOfflineSupplies()
  const { deleteEvidenceDrafts } = useOfflineEvidenceDrafts()
  const { terms } = useFetchTerms({ locale: i18n.locale })

  const {
    isModalVisible: isModalTermsVisible,
    toggleModal: toggleModalTermsVisible,
    closeModal: closeModalTerms,
  } = useModal()

  const goSelectLanguage = () => {
    navigation.navigate('Languages')
  }

  const goDeleteAccount = () => {
    navigation.navigate('DeleteAccount')
  }

  const handleSignOut = async () => {
    try {
      if (Platform.OS !== 'web') {
        await Promise.all([
          deleteUser(),
          dropAllQueue(),
          deleteAllDraftOffline(),
          deleteAllCropOffline(),
          resetSupplies(),
          deleteEvidenceDrafts(),
        ])
      }

      signOut()

      dispatch(removePin())

      dispatch(activitiesActions.removeFilters())

      await AsyncStorage.clear()

      await awsSignOut()

      dispatch(setIsAuth(false))
      dispatch(setUser(false))
      dispatch(setConfig(false))
    } catch (err) {
      console.error(err)
    }
  }

  const handleNotConnection = () => {
    console.warn('Not connection')
  }

  return (
    <>
      <View style={styles.container}>
        <ItemMenu
          onPress={isConnected ? goSelectLanguage : handleNotConnection}
          title={t('VIEWS.SETTINGS.LANGUAGE')}
          iconLeft={{
            name: 'LANGUAGE',
            size: 24,
            style: styles.iconItem,
          }}
          iconRight={{
            name: 'CHEVRON-RIGHT',
            size: 20,
            style: styles.iconRightItem,
          }}
          divider
        />
        <ItemMenu
          onPress={isConnected ? toggleModalTermsVisible : handleNotConnection}
          title={t('VIEWS.SETTINGS.TERMS')}
          iconLeft={{
            name: 'FILETYPE-LEGAL',
            size: 24,
            style: styles.iconItem,
          }}
          divider
        />
        <ItemMenu
          onPress={isConnected ? toggleModal : handleNotConnection}
          title={t('VIEWS.SETTINGS.SIGN_OUT')}
          divider
        />
        <ItemMenu
          title={t('VIEWS.SETTINGS.VERSION_BUILD', {
            version: APP_VERSION,
            build: APP_BUILD,
          })}
          titleStyle={styles.titleVersion}
        />
      </View>
      <View style={styles.bottom}>
        <Paragraph
          style={[styles.deleteAccount]}
          onPress={isConnected ? goDeleteAccount : handleNotConnection}
        >
          {t('VIEWS.SETTINGS.DELETE_ACCOUNT')}
        </Paragraph>
      </View>
      <ModalConfirm
        visible={isModalVisible}
        onConfirm={handleSignOut}
        onClose={toggleModal}
        title={t('VIEWS.SETTINGS.MODAL_CONFIRM_SIGN_OUT.TITLE')}
        contentText={t('VIEWS.SETTINGS.MODAL_CONFIRM_SIGN_OUT.MESSAGE')}
        cancelText={t('VIEWS.ACHIEVEMENT.MODAL_CONFIRM.TEXT_3')}
        confirmText={t('VIEWS.SETTINGS.MODAL_CONFIRM_SIGN_OUT.ACCEPT')}
      />
      {isModalTermsVisible && (
        <ModalTerms
          isModalVisible={isModalTermsVisible}
          t={t}
          terms={terms}
          closeModalTerms={closeModalTerms}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 10,
    backgroundColor: white,
  },
  bottom: {
    position: 'absolute',
    bottom: 44,
    width: '100%',
  },
  deleteAccount: {
    color: secondary600,
    alignSelf: 'center',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    height: 24,
    letterSpacing: 0.15,
  },
  iconItem: { color: blackHighEmphasis },
  iconRightItem: { color: grayInput },
  titleVersion: { color: blackInactive, fontSize: 14, fontWeight: '400' },
})

SettingsScreen.propTypes = {
  navigation: PropTypes.object,
}

export default SettingsScreen
