import React, { useCallback, useEffect, useState, useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { Button, Divider, List } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { useOfflineQueue } from '../offline/queries/queue'
import moment from 'moment'
import { useSync } from '@modules/common/hooks'
import { LanguageContext } from '@contextState/language'

function OfflineQueueScreen() {
  const { t } = useContext(LanguageContext)
  const TYPES_LABELS = {
    create_collaborator: t('VIEWS').OFFLINE_QUEUE.TEXT_1,
    common_update: t('VIEWS').OFFLINE_QUEUE.TEXT_2,
    common_create: t('VIEWS').OFFLINE_QUEUE.TEXT_3,
  }

  const { showQueue } = useOfflineQueue()
  const [queue, setQueue] = useState([])
  const [loadingSync, setLoadingSync] = useState(false)
  const { doSync } = useSync()

  const fetchQueue = useCallback(async () => {
    const result = await showQueue()
    setQueue(result)
  }, [])

  useEffect(() => {
    fetchQueue()
  }, [])

  async function handleSync() {
    setLoadingSync(true)
    await doSync()
    await fetchQueue()
    setLoadingSync(false)
  }

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        {queue.map((item, key) => (
          <React.Fragment key={key}>
            <List.Item
              description={moment(item.time).format('DD-MM-YYYY HH:mm a')}
              title={TYPES_LABELS[item.type]}
              right={() => (
                <List.Icon
                  icon={() => <CommonIcon name='SYNC-OFF' size={20} />}
                />
              )}
              subtitle={t('VIEWS').OFFLINE_QUEUE.TEXT_4}
            />
            <Divider />
          </React.Fragment>
        ))}
      </View>
      <Button
        loading={loadingSync}
        onPress={handleSync}
        disabled={queue.length === 0}
        style={{ position: 'relative', bottom: 0, margin: 16 }}
      >
        {t('VIEWS').OFFLINE_QUEUE.TEXT_5}
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
})

export default OfflineQueueScreen
