import { useContext } from 'react'
import { View, StyleSheet, Image, Text } from 'react-native'

import { CompanyDetails } from '@common/components'
import { useCompanyInfo } from '@modules/common/hooks'

import { blackHighEmphasis } from '@styles/palette'
import { OfflineEmpty } from '@common/components'
import { LanguageContext } from '@contextState/language'
import { cropsEmpty } from '@constants/images'
import { useSelector } from 'react-redux'

export const EmptyStateCropStory = ({ show }) => {
  const { identifier, companyName } = useCompanyInfo()
  const { t } = useContext(LanguageContext)
  const { isConnected } = useSelector((state) => state.connectionReducer)

  if (!show) return null

  return (
    <View style={styles.container}>
      <CompanyDetails
        companyName={companyName}
        companyIdentifier={identifier}
      />
      {isConnected ? (
        <>
          <Image source={cropsEmpty} style={styles.emptyImage} />

          <Text style={styles.message}>
            {t('VIEWS').CROP_LIST.COMPONENTS.EMPTY_STATE_CROP_STORY.TEXT_1}
          </Text>
        </>
      ) : (
        <OfflineEmpty />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  emptyImage: {
    width: 280,
    height: 220,
    marginTop: 55,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  message: {
    paddingHorizontal: 26,
    marginTop: 63,
    alignSelf: 'center',
    fontSize: 20,
    color: blackHighEmphasis,
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: 23,
  },
})
