import { makeRequest } from '@common/services'
import { useOfflineTypes } from '@offline/queries/types'
import { GlobalStateInterface } from '@store/interfaces'
import { setUnitTypeVolume } from '@store/slices'
import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

export const useUnitTypeVolume = () => {
  const dispatch = useDispatch()
  const { storeType, getTypes } = useOfflineTypes()
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )
  const fetchUnitTypeVolume = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'unit-types-volume' })

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('unitypesvolume', data)
      }
    } else {
      data = await getTypes('unitypesvolume')
    }
    const unitTypeVolumes = data.map((el: any) => ({
      label: `${el.key} (${el.keyLabel})`,
      value: el._id,
      key: el.key,
      unitMeasureSystem: el.unitMeasureSystem,
    }))
    dispatch(setUnitTypeVolume(unitTypeVolumes))
  }
  return {
    fetchUnitTypeVolume,
  }
}
