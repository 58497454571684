import React, { useContext, useEffect, useState, useCallback } from 'react'
import {
  AppState,
  View,
  ScrollView,
  StyleSheet,
  Platform,
  Text,
  Linking,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { Button, ActivityIndicator } from 'react-native-paper'
import moment from 'moment'
import { FormikProvider, useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { PropTypes } from 'prop-types'
import { parse } from 'wkt'
import { polygon, buffer, point, booleanPointInPolygon } from '@turf/turf'
import { green500 } from '@common/styles/palette'

import { CropContext } from '@contextState/crop'
import { SelectorContext } from '@contextState/selectors'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import AchievementsForm from '@components/activities/AchievementsForm'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import ButtonCustom from '@components/common/ButtonCustom'
import CommonList from '@components/common/CommonList'
import Layout from '@components/common/Layout'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import ModalEvidence from '@components/common/ModalEvidence'
import EiqCardSimple from '@components/common/v1/EiqCardSimple'
import { VerifyPin } from '@modules/common/components'
import useNetwork from '@utils/network'
import { useOfflineCommon } from '@offline/queries/common'
import { useOfflineDrafts } from '@offline/queries/drafts'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import { urlToBlob } from '@utils/files'
import { SUPPLY } from '@constants/iconGuide'
import useModal from '@hooks/useModal'
import useValidateAchievement from '@hooks/useValidateAchievement'
import {
  deleteDraft,
  getDraftById,
  insertDraft,
  updateDraft,
  getDraftsByGroup,
  insertFileToStorage,
  deleteFilesInStorage,
  getUserActive,
  deleteUserActive,
} from '@services/firebase'
import { getDescriptionSupplySelect } from '@utils/supply'
import { SnackbarRejectedActivity } from '@common/components/snackbar'
import EvidencesList from '@components/common/v1/EvidencesList'
import { formatDateMoment } from '@utils/date'
import { MODAL_ERRORS } from '@utils/constants'
import activityTypesConst from '@constants/activityTypes'
import { ModalLoading } from '@modules/common/components/ModalLoading'
import { useModalAnimated } from '@modules/common/hooks/useModalAnimated'
import { formatSurface } from '@common/utils'
import { carbon600 } from '@styles/palette'
import { SIZES } from '@common/components/icons/interfaces'
import { EActivityTypes } from '@common/enum'
import { IconBox } from '@modules/supplies/components'
import { useDispatch, useSelector } from 'react-redux'
import { setActivityTemporalData } from '@store/slices/activity-temporal-data.slice'
import { useAuth } from '@common/hooks'
import RouterNames from '@constants/routerNames'

let appStateListener
let deleteBeforeRemoveListener
let valuesForm = null

const AchievementsScreen = ({ route, navigation }) => {
  const { currentCrop, isVerifier, getSynchronizeServices, setCurrentCropId } =
    useContext(CropContext)
  const dispatch = useDispatch()
  valuesForm = route.params.valuesForm
  const { t, i18n } = useContext(LanguageContext)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)
  const { subTypeActivities } = useSelector(
    (state) => state.activityTypesReducer
  )
  const { user } = useAuth()
  const {
    clearSelectors,
    supplySelector,
    collaboratorSelector,
    setCollaboratorSelector,
    lotSelector,
    setLotSelector,
    lotSelectorData,
    setLotSelectorData,
    establishmentsSelector,
    setSupplySelector,
    setEstablishmentsSelector,
    jsonWkt,
    setJsonWkt,
    setSelectorTotalSurface,
  } = useContext(SelectorContext)
  const {
    showDeleteModal,
    setShowDeleteModal,
    setShowDeleteIcon,
    unitTypesSupplies,
  } = useContext(CommonContext)
  const { doRequest } = useNetwork()
  const { showIndividuals } = useOfflineCommon()
  const {
    findDraftByIdOffline,
    insertDraftOffline,
    updateDraftOffLine,
    deleteDraftByIdOffline,
  } = useOfflineDrafts()
  const activityType = activityTypes.find(
    (element) => element.tag === route.params.tag
  )
  const [activity, setActivity] = useState()
  const [isFetchingActivity, setIsFetchingActivity] = useState(false)
  const [evidences, setEvidences] = useState([])
  const [files, setFiles] = useState([])
  const [modal, setModal] = useState(false)
  const [totalEiqAllSupplies, setTotalEiqAllSupplies] = useState(0)
  const [eiqRange, setEiqRange] = useState('VERY_LOW')
  const [isFetchingDraft, setIsFetchingDraft] = useState(false)
  const [goBackEvent, setGoBackEvent] = useState()
  const [draft, setDraft] = useState()
  const [isOtherUserOnScreen, setIsOtherUserOnScreen] = useState(false)
  const [allowLeaveWithoutSaveChanges, setAllowLeaveWithoutSaveChanges] =
    useState(false)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [initialValues, setInitialValues] = useState({
    subTypeActivity: { value: '', label: '' },
    lots: { value: '', label: '' },
    surface: '',
    dateAchievement: {
      value: new Date(),
      label: formatDateMoment(new Date(), 'DD/MMM/YYYY', i18n.locale, true),
    },
  })

  const [userNameActive, setUserNameActive] = useState('')
  const {
    isModalVisible: isModalConfirmVisible,
    toggleModal: displayToggleModalConfirm,
    closeModal: closeModalConfirm,
  } = useModal()

  const {
    isModalVisible: isModalValidateVisible,
    toggleModal: displayToggleModalValidate,
    closeModal: closeModalValidate,
  } = useModal()
  const [loadingValidateVersion, setLoadingValidateVersion] = useState(false)
  const [isDeletingDraft, setIsDeletingDraft] = useState(false)

  const { isModalVisible, toggleModal, closeModal } = useModal()
  const { loading: loadingValidate, validateAchievement } =
    useValidateAchievement()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (draft) {
        verifyVersions(values)
      } else {
        onSubmit(values)
      }
    },
    enableReinitialize: false,
  })

  const {
    handleSubmit,
    setFieldValue,
    values,
    isSubmitting,
    setSubmitting,
    setValues,
    setFieldTouched,
    errors,
    touched,
  } = formik

  const {
    isModalVisible: isModalLoadingVisible,
    closeModal: closeModalLoading,
    title: titleModalLoading,
    subtitle: subtitleModalLoading,
    setModalIsVisible: setModalLoadingIsVisible,
  } = useModalAnimated({
    title: t('VIEWS.ACTIVITY_CREATE.MODAL_LOADING.COMPLETING_THE_APPLICATION'),
    subtitle: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MOMENTS'
    ),
    subtitleSecondary: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MORE_MOMENTS'
    ),
  })

  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      const { draftId } = route.params

      if (valuesForm) {
        setValues({ ...valuesForm })
      } else {
        if (draftId) {
          fetchDraft({
            draftId,
            currentUser: user,
          })
        } else {
          fetchActivity()
        }
      }

      return () => true
    }, [isConnected, activityTypes])
  )

  useFocusEffect(
    useCallback(() => {
      setCurrentCropId(route?.params?.id)
    }, [isConnected, route?.params?.id])
  )

  useEffect(() => {
    if (lotSelector.length) {
      recalculateIsInLot()
    }
  }, [lotSelector])

  useFocusEffect(
    useCallback(() => {
      if (isConnected && route.params.draftId) {
        const userFirebase = {
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
        }
        getUserActive(
          route.params.draftId,
          userFirebase,
          disabledScreen,
          enableScreen
        )
      } else {
        if (route.params.draftId) {
          enableScreen()
        }
      }

      return () => {
        deleteUserActiveInFirebase()
        return true
      }
    }, [])
  )

  const deleteUserActiveInFirebase = async () => {
    if (route?.params?.draftId) {
      deleteUserActive(route.params.draftId, user._id)
    }
  }

  /**
   * ADD LISTENERS IF USER LEAVE THE APP WITHOUT SAVE
   */
  useEffect(() => {
    let subscription
    if (appStateListener && subscription) {
      subscription.remove()
      appStateListener = false
    }

    if (isConnected && draft && !appStateListener) {
      subscription = AppState.addEventListener(
        'change',
        appStateChangeEventListener
      )

      appStateListener = true
    }

    return () => {
      if (appStateListener && subscription) {
        subscription.remove()
      }

      appStateListener = false
    }
  }, [isConnected, isOtherUserOnScreen])

  /**
   * ADD LISTENERS FOR PREVENT USER LEAVE SCREEN WITHOUT SAVE
   */
  useEffect(() => {
    if (deleteBeforeRemoveListener) {
      deleteBeforeRemoveListener()
    }

    deleteBeforeRemoveListener = navigation.addListener(
      'beforeRemove',
      beforeRemoveListener
    )

    if (Platform.OS === 'web') {
      window.removeEventListener('beforeunload', beforeunloadListener, false)
      window.addEventListener('beforeunload', beforeunloadListener, false)
    }

    return () => {
      deleteBeforeRemoveListener()

      if (Platform.OS === 'web') {
        window.removeEventListener('beforeunload', beforeunloadListener, false)
      }
    }
  }, [
    navigation,
    isConnected,
    isOtherUserOnScreen,
    allowLeaveWithoutSaveChanges,
    draft,
    activity,
    values,
    lotSelector,
    collaboratorSelector,
    evidences,
    supplySelector,
    establishmentsSelector,
  ])

  useEffect(() => {
    if (supplySelector) {
      setFieldValue('supplies', supplySelector)

      let lotsWithSurface = []

      if (lotSelectorData.length && lotSelectorData[0].surfaceRealized) {
        lotsWithSurface = lotSelectorData
      }

      if (draft && draft?.lotsWithSurface?.length) {
        lotsWithSurface = draft.lotsWithSurface.map(
          ({ surfaceAchievement }) => ({ surfaceRealized: surfaceAchievement })
        )
      }

      setTotalEiqAllSupplies(
        getAchievementEiq({
          supplies: supplySelector,
          lotsWithSurface,
        })
      )
    }
  }, [supplySelector, lotSelectorData, draft?.lotsWithSurface])

  useEffect(() => {
    setFieldValue('signers', collaboratorSelector)
  }, [collaboratorSelector])

  useEffect(() => {
    setEiqRange(getEIQRange(totalEiqAllSupplies))
  }, [totalEiqAllSupplies])

  useEffect(() => {
    return () => {
      clearSelectors()
    }
  }, [])

  useEffect(() => {
    if (loadingValidate) {
      setModalLoadingIsVisible(true)
    } else {
      setModalLoadingIsVisible(false)
    }

    return () => {
      closeModalLoading()
    }
  }, [loadingValidate])

  const getAchievementEiq = ({ supplies, lotsWithSurface }) => {
    const surfaceRealized = lotsWithSurface.reduce(
      (subAccumulator, { surfaceRealized }) => subAccumulator + surfaceRealized,
      0
    )

    return calculateEiq(supplies, surfaceRealized)
  }

  const disabledScreen = (name) => {
    setIsOtherUserOnScreen(true)
    setShowDeleteIcon(false)
    setUserNameActive(name)
  }

  const enableScreen = () => {
    setShowDeleteIcon(true)
  }

  const fetchCrop = useCallback(
    async (lots) => {
      try {
        const auxJsonWkt = {}
        let crop
        if (isConnected) {
          const response = await doRequest({
            method: 'GET',
            version: 'v2',
            url: `crops/${route.params.id}`,
            params: {
              companyId: user.config.companySelected?._id,
            },
            displayAlert: false,
          })
          crop = response.data
        } else {
          const offlineData = await showIndividuals('crops', route.params.id)
          crop = offlineData
        }
        if (!crop) {
          navigation.navigate('ModalErrorDeepLinks', {
            error: MODAL_ERRORS.ERROR_UNEXPECTED,
          })
          throw new Error(MODAL_ERRORS.ERROR_UNEXPECTED)
        }
        const me = crop.members.find((el) => el.user._id === user._id)
        if (!me) {
          navigation.navigate('ModalErrorDeepLinks', {
            error: MODAL_ERRORS.UNAUTHORIZED,
          })
        }
        crop.establishments.forEach((establishment) => {
          establishment.lots.map((lot) => {
            if (lots.indexOf(lot._id) !== -1) {
              auxJsonWkt[lot._id] = {
                wktFormat: lot?.lot?.wktFormat || lot?.wktFormat,
                area: lot?.lot?.area || lot?.area,
              }
            }
          })
        })
        setJsonWkt(auxJsonWkt)
      } catch (error) {
        navigation.navigate('ModalErrorDeepLinks', {
          error: MODAL_ERRORS.ERROR_UNEXPECTED,
        })
        throw new Error(MODAL_ERRORS.ERROR_UNEXPECTED)
      }
    },
    [isConnected]
  )
  /**
   * FETCH DRAFT OF FIREBASE OR LOCAL DATABASE
   *
   * @param {*} draftId
   * @param {*} currentUser
   * @returns Void
   */
  const fetchDraft = async ({ draftId }) => {
    setIsFetchingDraft(true)

    let draft

    if (isConnected) {
      draft = await getDraftById(draftId)
    } else {
      const responseDraft = await findDraftByIdOffline(draftId)

      draft = responseDraft
    }

    if (!draft) {
      navigation.navigate('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.NOT_AVAILABLE,
      })
      return
    }

    setIsFetchingDraft(false)

    if (draft) {
      setDraft(draft)

      if (draft.lots?.length) {
        setLotSelector(draft.lots)
        fetchCrop(draft.lots)
      }

      if (draft.lotsData?.length) {
        setLotSelectorData(draft.lotsData)
      }

      if (draft.lotsWithSurface?.length) {
        setEstablishmentsSelector(draft.lotsWithSurface)
      }

      if (draft.supplies) {
        setSupplySelector(draft.supplies)
      }

      if (draft.evidences) {
        setEvidences(draft.evidences)
      }

      if (draft.signers) {
        setCollaboratorSelector(draft.signers)
      }

      const result = {
        ...draft,
        surface: draft.surface > 0 ? String(draft.surface) : '',
        dateAchievement: {
          value: draft?.dateAchievement,
          label: formatDateMoment(
            draft?.dateAchievement,
            'DD/MMM/YYYY',
            i18n.locale,
            true
          ),
        },
        lots: {
          value: draft.lots,
          label: draft.lots.length
            ? `${draft.lots.length} ${t(
                'COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_1'
              )}`
            : '',
        },
        lotSelector: draft.lots.length ? draft.lots : [],
        lotSelectorTotalSurface: draft.surface,
      }
      setSelectorTotalSurface(draft.surface)
      if (draft.subTypeActivity) {
        const valueSubtype = subTypeActivities.find(
          (subType) => subType.value === draft.subTypeActivity
        )
        if (valueSubtype) {
          result.subTypeActivity = {
            label: valueSubtype.label,
            value: valueSubtype.value,
          }
        }
      }
      setValues({ ...result })

      setInitialValues({ ...result })
    }
  }

  const fetchActivity = async () => {
    setIsFetchingActivity(true)

    let activity

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `activities/${route.params.activity}`,
      })

      await getSynchronizeServices(route.params.id)

      activity = response.data
    } else {
      const offlineData = await showIndividuals(
        'activities',
        route.params.activity
      )

      activity = offlineData
    }

    if (!activity) {
      navigation.navigate('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.NOT_AVAILABLE,
      })
      return
    }

    setActivity(activity)

    setIsFetchingActivity(false)
  }

  /**
   * VALIDATE IF USER LEAVE THE APP WITHOUT SAVE
   *
   * @param {*} event
   * @returns
   */
  const appStateChangeEventListener = (nextAppState) => {
    if (
      (nextAppState === 'inactive' || nextAppState === 'background') &&
      !isOtherUserOnScreen
    ) {
      deleteUserActiveInFirebase()
    }

    if (nextAppState === 'active') {
      if (valuesForm) {
        setValues({ ...valuesForm })
      } else {
        fetchDraft({
          draftId: route.params.draftId,
          currentUser: user,
        })
      }
    }
  }

  /**
   * VALIDATE IF USER ATTEMPT GO BACK WITHOUT SAVE USING NAVIGATOR IN THE APP
   *
   * @param {*} event
   * @returns
   */
  const beforeRemoveListener = (event) => {
    if (
      allowLeaveWithoutSaveChanges ||
      !hasUnsavedChanges(
        draft,
        activity,
        values,
        lotSelector,
        collaboratorSelector,
        evidences,
        supplySelector
      ) ||
      (route.params.tag !== activityTypesConst.ACT_TILLAGE.tag &&
        route.params.tag !== activityTypesConst.ACT_SOWING.tag &&
        route.params.tag !== activityTypesConst.ACT_APPLICATION.tag &&
        route.params.tag !== activityTypesConst.ACT_FERTILIZATION.tag)
    ) {
      setShowDeleteIcon(false)
      clearSelectors()
      return
    }

    event.preventDefault()

    displayToggleModalConfirm()

    setGoBackEvent(event)
  }

  /**
   * VALIDATE IF USER ATTEMPT LEAVE OR REFRESH WITHOUT SAVE ON WEB
   *
   * @param {*} event
   * @returns
   */
  const beforeunloadListener = (event) => {
    if (
      allowLeaveWithoutSaveChanges ||
      !hasUnsavedChanges(
        draft,
        activity,
        values,
        lotSelector,
        collaboratorSelector,
        evidences,
        supplySelector
      ) ||
      (route.params.tag !== activityTypesConst.ACT_TILLAGE.key &&
        route.params.tag !== activityTypesConst.ACT_SOWING.key &&
        route.params.tag !== activityTypesConst.ACT_APPLICATION.key &&
        route.params.tag !== activityTypesConst.ACT_FERTILIZATION.key)
    ) {
      setShowDeleteIcon(false)
      clearSelectors()

      return
    }

    event.preventDefault()

    return (event.returnValue = t('VIEWS').ACHIEVEMENT.MODAL_CONFIRM.TEXT_2)
  }

  /**
   * VALIDATE IF USER HAS CHANGES WITHOUT SAVE
   *
   * @returns bool
   */
  const hasUnsavedChanges = (
    draft,
    activity,
    values,
    lotSelector,
    collaboratorSelector,
    evidences,
    supplySelector
  ) => {
    if (draft) {
      const compareData = {
        subTypeActivity: draft.subTypeActivity,
        lots: draft.lots,
        surface: draft.surface > 0 ? String(draft.surface) : '',
        dateAchievement: moment(draft.dateAchievement).format('DD/MM/YYYY'),
        supplies: draft.supplies.map((element) => {
          return {
            name: element.name,
            total: element.total,
            surface: element.surface,
          }
        }),
        evidences: draft.evidences,
        signers: draft.signers.map((element) => element.userId),
      }

      const currentData = {
        subTypeActivity: values.subTypeActivity,
        lots: lotSelector,
        surface: String(values.surface),
        dateAchievement: moment(values.dateAchievement).format('DD/MM/YYYY'),
        supplies: supplySelector.map((element) => {
          return {
            name: element.name,
            total: element.total,
            surface: element.surface,
          }
        }),
        evidences,
        signers: collaboratorSelector.map((element) => element.userId),
      }

      return JSON.stringify(compareData) !== JSON.stringify(currentData)
    }

    const compareData = {
      subTypeActivity: activity?.subTypeActivity?._id ?? '',
      lots: [],
      surface: '',
      dateAchievement: moment().format('DD/MM/YYYY'),
      supplies: [],
      evidences: [],
      signers: [],
    }

    const currentData = {
      subTypeActivity: values.subTypeActivity,
      lots: lotSelector,
      surface: String(values.surface),
      dateAchievement: moment(values.dateAchievement).format('DD/MM/YYYY'),
      supplies: supplySelector.map((element) => {
        return {
          name: element.name,
          total: element.total,
          surface: element.surface,
        }
      }),
      evidences,
      signers: collaboratorSelector.map((element) => element.userId),
    }

    return JSON.stringify(compareData) !== JSON.stringify(currentData)
  }

  function enableValidate(values) {
    if (
      !(
        values.lots !== '' &&
        values.surface !== '' &&
        values?.subTypeActivity?.value !== ''
      ) ||
      (supplySelector.length === 0 &&
        activityType?.tag !== 'ACT_HARVEST' &&
        activityType?.tag !== 'ACT_TILLAGE') ||
      activityType?.tag === 'ACT_HARVEST' ||
      collaboratorSelector.length === 0 ||
      collaboratorSelector.length === 1 ||
      evidences.length === 0
    ) {
      return true
    } else {
      return false
    }
  }

  function supplyNavigation(surface) {
    valuesForm = values

    navigation.navigate(RouterNames.SEARCHER_SUPPLIES, {
      id: route.params.id,
      tag: route.params.tag,
      activity: route.params.activity,
      surface,
      valuesParams: {
        id: route.params.id,
        activity: route.params.activity,
        tag: route.params.tag,
        draftId: route.params.draftId,
        valuesForm: values,
      },
      routeRedirect: 'AchievementsScreen',
      alphaCode: currentCrop?.company?.country?.alphaCode,
      cropType: currentCrop?.cropType?.key,
      activityType: route.params.tag,
      suppliesSelected: supplySelector,
    })
  }

  const setDataForm = (values) => {
    const lotsWithSurface = establishmentsSelector
    const data = {
      _id: draft ? draft._id : undefined,
      dateAchievement: moment.isMoment(values.dateAchievement?.value)
        ? values.dateAchievement?.value?.toDate()
        : new Date(values.dateAchievement?.value),
      activity: route.params.activity,
      surface:
        typeof values.surface === 'string'
          ? Number(values.surface.replace(',', '.'))
          : values.surface,
      subTypeActivity: values.subTypeActivity?.value,
      crop: route.params.id,
      tag: route.params.tag,
      lots: lotsWithSurface.map((element) => element.lot),
      lotsWithSurface,
      signers: collaboratorSelector.map((element) => {
        if (element.userId !== user._id) {
          return {
            ...element,
            signed: false,
            dateToSign: new Date(),
          }
        }
        return element
      }),
      supplies: supplySelector.map((element) => ({
        ...element,
        typeId: element.typeId?._id ?? element.typeId,
        supply: element.supplyId || element.supply || element._id,
        typeSupply:
          typeof element.typeId === 'object'
            ? element?.typeId
            : element?.typeSupply,
      })),
      evidences,
      version: draft ? draft.version : undefined,
      createdByUserId: draft ? draft.createdByUserId : undefined,
      createdByUsername: draft ? draft.createdByUsername : undefined,
      updatedByUserId: user._id,
      updatedByUsername: `${user.firstName} ${user.lastName}`,
      createdAt: draft?.createdAt ?? undefined,
      draftGroupId: draft?.draftGroupId ?? undefined,
      hasMoreVersion: draft?.hasMoreVersion ?? undefined,
      lotsData: lotSelectorData.map((element) => ({
        _id: element._id,
      })),
      company: user.config.companySelected,
      isRejected: values.isRejected,
    }

    return data
  }

  const onSaveDraft = async (values) => {
    setIsSavingDraft(true)

    setAllowLeaveWithoutSaveChanges(true)

    const data = setDataForm(values)

    for (let evidence of data.evidences) {
      if (evidence.persisted || evidence.fileId) {
        continue
      }

      const fileExtension = evidence.file.name.substring(
        evidence.file.name.lastIndexOf('.'),
        evidence.file.name.length
      )

      const evidenceFileName = uuidv4() + fileExtension

      evidence.type = evidence.file.type
      evidence.name = evidence.file.name
      evidence.fileNamePath = evidenceFileName

      if (isConnected) {
        let blobFile

        if (Platform.OS !== 'web') {
          blobFile = await urlToBlob({
            url: evidence.file.uri,
          })
        }

        const fileUrl = await insertFileToStorage({
          blobFile: blobFile ?? evidence.file,
          fileName: evidenceFileName,
          fileType: evidence.type,
        })

        evidence.file = fileUrl
        evidence.persisted = true
      } else {
        evidence = {
          achievementId: data._id,
          fileName: evidence.name,
          fileNamePath: evidenceFileName,
          fileType: evidence.file.type,
          fileUri: evidence.file.uri,
          date: new Date(evidence.date).toISOString(),
          description: evidence.description,
          location: evidence.location ? JSON.stringify(evidence.location) : '',
          meta: evidence.meta ? JSON.stringify(evidence.meta) : '',
          persisted: evidence.persisted,
          settings: evidence.settings ? JSON.stringify(evidence.settings) : '',
        }
      }
    }

    const userFirebase = {
      _id: user._id,
      name: `${user.firstName} ${user.lastName}`,
    }

    if (isConnected) {
      if (!draft) {
        await insertDraft(data, userFirebase)
      } else {
        data.id = route.params.draftId
        data.isRejected = false
        data.rejectedAt = undefined
        data.rejectObservation = undefined
        await updateDraft(data, userFirebase)

        deleteFilesInStorage(
          draft.draftGroupId,
          route.params.draftId,
          getFilesDeleted()
        )
      }
    } else {
      if (!draft) {
        await insertDraftOffline(data, userFirebase)
      } else {
        await updateDraftOffLine(route.params.draftId, data, userFirebase)
      }
    }

    setIsSavingDraft(false)

    clearSelectors()

    navigation.goBack()
  }

  const onSubmit = async (values) => {
    const data = setDataForm(values)

    const temporalData = {
      dataToValidate: data,
      cropName: currentCrop.name,
      supplies: supplySelector,
      evidences: data.evidences,
      draftId: route.params.draftId,
      displayDraftVersionModal: draft?.hasMoreVersion,
      isAchievement: true,
      isDirectAchievement: false,
      routeRedirect: {
        name: RouterNames.ACTIVITY_ACHIEVEMENT_LIST,
        params: {
          cropId: route.params.id,
          mode: route.params.mode,
          tag: route.params.tag,
          activityId: route.params.activity,
        },
      },
    }

    dispatch(setActivityTemporalData(temporalData))

    setAllowLeaveWithoutSaveChanges(true)

    setSubmitting(false)

    return navigation.navigate(RouterNames.ACTIVITY_CREATE_CONFIRM, {
      cropId: route.params.id,
      activityTypeTag: route.params.tag,
      isPlanification: false,
    })
  }

  const calculateIsInLot = (location) => {
    let isInLot
    if (lotSelectorData && Object.keys(location).length > 0) {
      isInLot = lotSelectorData.find((element) => {
        let geoJson = null
        const lotWithWkt = jsonWkt[element._id]
        if (lotWithWkt) {
          geoJson = parse(lotWithWkt.wktFormat)
        } else if (element.area) {
          geoJson = polygon([element.area])
        }
        if (geoJson) {
          const buffered = buffer(geoJson, 20, { units: 'meters' })
          const pt = point([location.longitude, location.latitude])

          const isInside = booleanPointInPolygon(pt, buffered)
          return isInside
        }
        return false
      })
    }
    return isInLot
  }

  const recalculateIsInLot = () => {
    if (!evidences.length) {
      return null
    }
    const auxEvidences = evidences
    auxEvidences.forEach((element, index) => {
      const elementLocation = element?.meta?.location
      if (elementLocation) {
        const isInLot = calculateIsInLot(elementLocation)
        auxEvidences[index].settings.isInLot = Boolean(isInLot)
      }
    })
    setEvidences(auxEvidences)
  }

  function handleSelectEvidence({ values, file, location, setFieldValue }) {
    let resultEvidences = []
    const isInLot = calculateIsInLot(location)
    resultEvidences = [
      ...evidences,
      {
        ...values,
        meta: { location },
        settings: {
          isInLot: Boolean(isInLot),
          isValidateLot: values.isValidateLot,
        },
        user: user._id,
        date: values?.date ?? new Date(),
        userCreate: user,
        name: values.file.name,
      },
    ]

    setEvidences(resultEvidences)

    if (setFieldValue) setFieldValue('evidences', resultEvidences)

    setFiles([...files, { file }])
  }

  function handleFileDelete(index) {
    if (!evidences[index].fileId) {
      setFiles(files.filter((el, key) => key !== index))
    }
    setEvidences(evidences.filter((el, key) => key !== index))
  }

  const successPin = async (data = null) => {
    closeModal()

    try {
      await validateAchievement(typeof data === 'object' ? data : null)
      clearSelectors()
      closeModal()
      navigation.goBack()
    } catch (error) {
      alert(error.error)
    }
  }

  /**
   * GET FILES DELETED FOR DELETE IN STORAGE
   */
  const getFilesDeleted = () => {
    const filesDeleted = []
    draft.evidences.forEach((draftEvidence) => {
      let fileExist = false
      const draftEvidenceName = draftEvidence.fileNamePath || draftEvidence.name
      evidences.forEach((evidence) => {
        const evidenceName = evidence.fileNamePath || evidence.name
        if (draftEvidenceName === evidenceName) {
          fileExist = true
        }
      })

      if (!fileExist) {
        filesDeleted.push(draftEvidenceName)
      }
    })

    return filesDeleted
  }

  /**
   * CONFIRM GO BACK WITHOUT SAVING CHANGES
   */
  const confirmGoBack = () => {
    setShowDeleteIcon(false)
    clearSelectors()
    closeModalConfirm()

    navigation.dispatch(goBackEvent.data.action)
  }

  const closeModalDelete = () => {
    setShowDeleteModal(false)
  }

  const onDeleteDraft = async () => {
    setIsDeletingDraft(true)

    setShowDeleteIcon(false)

    closeModalDelete()

    if (isConnected) {
      const namesFilesToDelete = evidences
        .filter((element) => element.persisted)
        .map((element) => element.fileNamePath || element.name)

      await deleteFilesInStorage(
        draft.activity,
        route.params.draftId,
        namesFilesToDelete
      )

      await deleteDraft(route.params.draftId, draft.draftGroupId)
    } else {
      await deleteDraftByIdOffline(route.params.draftId)
    }

    setAllowLeaveWithoutSaveChanges(true)

    clearSelectors()

    setIsDeletingDraft(false)

    navigation.goBack()
  }

  const verifyVersions = async (values) => {
    if (isConnected) {
      setLoadingValidateVersion(true)
      const response = await getDraftsByGroup(values.draftGroupId)
      setLoadingValidateVersion(false)
      if (response.length > 1) {
        displayToggleModalValidate()
      } else {
        onSubmit(values)
      }
    } else {
      onSubmit(values)
    }
  }

  const onPressEvidenceFile = (evidence) => {
    if (evidence.persisted || evidence.fileId) {
      const url = evidence.fileId ? evidence.path : evidence.file
      Platform.OS === 'web' ? window.open(url, '_blank') : Linking.openURL(url)
    }
  }

  if (
    (!draft && (isFetchingActivity || isFetchingDraft)) ||
    isDeletingDraft ||
    !currentCrop?._id
  ) {
    return (
      <ActivityIndicator size='large' color={green500} style={styles.loader} />
    )
  }

  const hasUnsavedChangesOnForm = hasUnsavedChanges(
    draft,
    activity,
    values,
    lotSelector,
    collaboratorSelector,
    evidences,
    supplySelector
  )

  function navigateToSupplyDetail(supply, key) {
    navigation.navigate(RouterNames.SUPPLY_LOADER, {
      id: supply.supplyId,
      key: key,
      item: supply,
      surface: values.surface,
      valuesParams: {
        id: route.params.id,
        activity: route.params.activity,
        tag: route.params.tag,
        draftId: route.params.draftId,
        valuesForm: values,
      },
      routeRedirect: 'AchievementsScreen',
    })
  }

  return (
    <View style={styles.formInner}>
      <ScrollView contentContainerStyle={styles.container}>
        {currentCrop && (
          <View>
            <Text style={styles.cropTitleText}>{currentCrop?.name}</Text>
            <Text style={styles.cropSubtitleText}>
              {formatSurface(currentCrop?.surface, currentCrop?.areaUnit)} -{' '}
              {currentCrop?.company.name}
            </Text>
          </View>
        )}

        <View style={styles.snackbar}>
          <SnackbarRejectedActivity draft={draft} i18n={i18n} />
        </View>

        {draft && draft?.version && (
          <View style={styles.editorDraftContainer}>
            <Text style={styles.editorDraftVersionText}>
              {t('VIEWS.ACHIEVEMENT.TEXT_8', {
                version: draft.version,
              })}
            </Text>
            <Text style={styles.editorDraftUserText}>
              {t('VIEWS.ACHIEVEMENT.TEXT_7', {
                user: draft.updatedByUsername,
                date: moment(draft.updatedAt).format('DD/MM/YYYY'),
                hour: moment(draft.updatedAt).format('HH:MM'),
              })}
            </Text>
          </View>
        )}
        {isOtherUserOnScreen && (
          <View style={styles.otherUserOnScreenContainer}>
            <Text style={styles.otherUserOnScreenText}>
              {t('VIEWS.ACHIEVEMENT.TEXT_5', {
                userFullname: userNameActive,
              })}
            </Text>
          </View>
        )}
        <Layout>
          <FormikProvider value={formik}>
            <AchievementsForm
              setFieldValue={setFieldValue}
              navigation={navigation}
              route={route}
              values={values}
              readOnly={isOtherUserOnScreen || isVerifier()}
              activityType={activityType}
              activity={activity}
              setFieldTouched={setFieldTouched}
              errors={errors}
              touched={touched}
              tag={route.params.tag}
            />
          </FormikProvider>

          {activityType?.tag !== 'ACT_TILLAGE' ? (
            <Button
              disabled={
                lotSelector.length === 0 ||
                !values.surface ||
                isOtherUserOnScreen ||
                isVerifier()
              }
              onPress={() => supplyNavigation(values.surface)}
              labelStyle={
                lotSelector.length === 0 ? styles.textDisabled : styles.text
              }
            >
              {t('VIEWS').ACHIEVEMENT.TEXT_1}
            </Button>
          ) : null}

          <Button
            mode='text'
            disabled={
              lotSelector.length === 0 || isOtherUserOnScreen || isVerifier()
            }
            onPress={() => setModal(true)}
          >
            {t('VIEWS').ACHIEVEMENT.TEXT_2}
          </Button>

          {activityType?.tag === 'ACT_APPLICATION' && (
            <EiqCardSimple
              title={`${t('VIEWS').ACHIEVEMENT.TEXT_3}  ${totalEiqAllSupplies}`}
              range={eiqRange}
              tag='ACT_APPLICATION'
            />
          )}

          {supplySelector.map((el, key) => {
            const code = el.typeSupply
              ? el.typeSupply.code
              : typeof el.typeId === 'object'
              ? el.typeId.code
              : null
            return (
              <CommonList
                key={key}
                index={key}
                img={SUPPLY[code] || SUPPLY['default']}
                iconRender={
                  el.isGeneric || el?.supply?.isGeneric
                    ? () => (
                        <IconBox
                          iconName={EActivityTypes.ACT_FERTILIZATION}
                          size={SIZES.MEDIUM}
                          color={carbon600}
                          style={styles.iconRender}
                        />
                      )
                    : null
                }
                onPress={() => navigateToSupplyDetail(el, key)}
                data={getDescriptionSupplySelect(
                  {
                    ...el,
                    unitName:
                      el.unitName ||
                      unitTypesSupplies.find(
                        (unit) =>
                          unit?.key?.toLowerCase() === el?.unit?.toLowerCase()
                      )?.keyLabel,
                  },
                  currentCrop.areaUnit
                )}
              />
            )
          })}

          <EvidencesList
            evidences={evidences}
            onDelete={handleFileDelete}
            onPress={onPressEvidenceFile}
            disabled={isOtherUserOnScreen}
          />

          <CollaboratorsButton
            title={t('COMPONENTS.COLLABORATORS_BUTTON.COLLABORATORS')}
            quantity={collaboratorSelector.length}
            onPress={() => {
              if (isOtherUserOnScreen) {
                return
              }

              valuesForm = values

              navigation.navigate('CollaboratorsSelectorModal', {
                id: route.params.id,
                status: isVerifier() ? 'readOnly' : '',
                valuesParams: {
                  id: route.params.id,
                  activity: route.params.activity,
                  tag: route.params.tag,
                  draftId: route.params.draftId,
                  valuesForm: values,
                },
                routeRedirect: 'AchievementsScreen',
              })
            }}
          />
        </Layout>
        {!isOtherUserOnScreen && (
          <ButtonCustom
            disabled={!hasUnsavedChangesOnForm || isSavingDraft || isSubmitting}
            isLoading={isSavingDraft}
            onPress={() =>
              hasUnsavedChangesOnForm ? onSaveDraft(values) : false
            }
            styles={[
              styles.buttonSave,
              isSavingDraft || !hasUnsavedChangesOnForm || isSubmitting
                ? styles.buttonDisabledSave
                : styles.buttonSaveBackground,
            ]}
            labelStyle={[
              styles.textButtonSave,
              !hasUnsavedChangesOnForm ? styles.textButtonDisabledSave : {},
            ]}
          >
            {t('VIEWS').ACHIEVEMENT.TEXT_6}
          </ButtonCustom>
        )}

        {!isOtherUserOnScreen && (
          <ButtonCustom
            disabled={
              enableValidate(values) ||
              isSubmitting ||
              loadingValidateVersion ||
              isSavingDraft ||
              !isConnected ||
              values?.isRejected === true
            }
            isLoading={isSubmitting}
            onPress={handleSubmit}
            styles={
              enableValidate(values) ||
              isSubmitting ||
              loadingValidateVersion ||
              isSavingDraft ||
              !isConnected ||
              values?.isRejected === true
                ? styles.buttonDisabled
                : styles.button
            }
            labelStyle={
              enableValidate(values) ||
              isSubmitting ||
              loadingValidateVersion ||
              isSavingDraft ||
              !isConnected
                ? styles.textButtonDisabled
                : styles.textButton
            }
          >
            {t('VIEWS').ACHIEVEMENT.TEXT_4}
          </ButtonCustom>
        )}
      </ScrollView>

      <ModalConfirm
        visible={isModalValidateVisible}
        onClose={closeModalValidate}
        onConfirm={() => {
          displayToggleModalValidate()
          onSubmit(values)
        }}
        colorButton={'secondary'}
        title={t('VIEWS').ACHIEVEMENT.MODAL_VALIDATE.TEXT_1}
        contentText={t('VIEWS').ACHIEVEMENT.MODAL_VALIDATE.TEXT_2}
        cancelText={t('VIEWS').ACHIEVEMENT.MODAL_VALIDATE.TEXT_3}
        confirmText={t('VIEWS').ACHIEVEMENT.MODAL_VALIDATE.TEXT_4}
        cancelButtonStyle={styles.deleteModalCancelButtonStyle}
        cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />

      <VerifyPin
        isModalVisible={isModalVisible}
        toggleModal={() => {
          toggleModal()
          setSubmitting(false)
        }}
        onSuccess={successPin}
        externalLoading={loadingValidate}
      />

      <ModalConfirm
        visible={isModalConfirmVisible}
        onClose={closeModalConfirm}
        onCancel={confirmGoBack}
        onConfirm={closeModalConfirm}
        title={t('VIEWS').ACHIEVEMENT.MODAL_CONFIRM.TEXT_1}
        contentText={t('VIEWS').ACHIEVEMENT.MODAL_CONFIRM.TEXT_2}
        cancelText={t('VIEWS').ACHIEVEMENT.MODAL_CONFIRM.TEXT_3}
        confirmText={t('VIEWS').ACHIEVEMENT.MODAL_CONFIRM.TEXT_4}
      />

      <ModalConfirm
        visible={showDeleteModal}
        onClose={closeModalDelete}
        onConfirm={onDeleteDraft}
        title={t('VIEWS').ACHIEVEMENT.MODAL_DELETE.TEXT_1}
        contentText={t('VIEWS').ACHIEVEMENT.MODAL_DELETE.TEXT_2}
        cancelText={t('VIEWS').ACHIEVEMENT.MODAL_DELETE.TEXT_3}
        confirmText={t('VIEWS').ACHIEVEMENT.MODAL_DELETE.TEXT_4}
        cancelButtonStyle={styles.deleteModalCancelButtonStyle}
        cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />

      <ModalEvidence
        showCameraRollOption={Platform.OS !== 'web'}
        visible={modal}
        onSelect={handleSelectEvidence}
        handleClose={() => setModal(false)}
        setFieldValue={setFieldValue}
      />

      <ModalLoading
        visible={isModalLoadingVisible}
        title={titleModalLoading}
        subtitle={subtitleModalLoading}
        animationStyle={styles.animationStyle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  otherUserOnScreenContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#4F4F4F',
    padding: 16,
    marginVertical: 4,
    marginHorizontal: 16,
    borderRadius: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  otherUserOnScreenText: {
    fontSize: 14,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  cropTitleText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
  },
  cropSubtitleText: {
    fontSize: 14,
    lineHeight: 20,
    color: '#333333B2',
  },
  editorDraftContainer: {
    backgroundColor: '#CFD8DC',
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 17.2,
    paddingBottom: 10.8,
    marginTop: 16,
    marginBottom: 28,
    borderRadius: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  editorDraftVersionText: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 'bold',
    letterSpacing: 0.25,
  },
  container: {},
  formInner: {
    flex: 1,
    padding: 16,
  },
  listItem: {
    paddingLeft: 0,
  },
  button: {
    backgroundColor: '#4CAF50',
  },
  buttonSaveBackground: {
    backgroundColor: '#f2f2f2',
    borderColor: '#4CAF50',
    borderWidth: 1,
  },
  buttonDisabledSave: {
    backgroundColor: '#f2f2f2',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderWidth: 1,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
  textButtonDisabledSave: {
    color: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: 'white',
  },
  textButtonSave: {
    color: '#4CAF50',
  },
  textDisabled: {
    color: '#A5D6A7',
  },
  text: {
    color: '#4CAF50',
  },
  deleteModalCancelButtonStyle: {
    borderColor: '#FF7043',
  },
  deleteModalCancelButtonTextStyle: {
    color: '#FF7043',
  },
  deleteModalConfirmButtonStyle: {
    backgroundColor: '#FF7043',
  },
  buttonSave: {
    marginBottom: 10,
    borderColor: '#4CAF50',
  },
  snackbar: {
    marginTop: 10,
  },
  animationStyle: {
    width: 300,
    height: 300,
  },
  iconRender: {
    width: 50,
    height: 50,
  },
})

AchievementsScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default AchievementsScreen
