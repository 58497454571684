import {
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  ReactElement,
} from 'react'
import { View, StyleSheet, Platform, FlatList } from 'react-native'

import activityTypesConstants from '@constants/activityTypes'
import userTypes from '@constants/userTypes'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { CropMenuWeb, CropMenuMobile } from '@modules/common/components'
import {
  EmptyState,
  FilterEmptyState,
} from '@modules/activities/screens/activitiesList/v1/components'
import { CropDetails } from '@modules/common/components'
import { ActivityIndicator } from '@modules/common/components'
import {
  searchErrorInAcVerification,
  ORDER_MENU_ENUM,
  CROP_MENU_OPTIONS,
} from '@modules/common/utils'
import { useAuth, useCropMenu, useFetchCrop } from '@common/hooks'
import { SnackbarLicensesPending } from '../v1/components'
import {
  ActivityCard,
  FabButton,
  ValidateModal,
  NoLongerFieldsModal,
} from './components'
import useModal from '@hooks/useModal'
import { useFab, useFetchActivities, useFetchPendingLicenses } from './hooks'
import { MODAL_ERRORS } from '@utils/constants'
import { primary500, grayBackground } from '@styles/palette'
import { enterToCropStory } from '@services/analytics'
import { useSelector } from 'react-redux'
import { selectHasFilter } from '@store/selectors/activities'
import { ACTIVITY_CONDITIONS } from '@modules/common/utils'
import { isDirectAchievementValidate } from './utils'
import {
  userPermissions,
  validateRoleInUserConfigService,
} from '@utils/permissions'
import { GlobalStateInterface } from '@store/interfaces'
import RouterNames from '@constants/routerNames'

const modes: any = {
  ACT_SOWING: 'sowing',
  ACT_TILLAGE: 'tillage',
  ACT_FERTILIZATION: 'fertilization',
  ACT_APPLICATION: 'application',
}

const ActivityList: FC<any> = ({
  route: { params },
  navigation,
}: any): ReactElement => {
  const { cropId } = useRef(params).current
  const { activityTypes } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )
  const { t }: any = useContext(LanguageContext)
  const { orderCropsBy }: any = useContext(CommonContext)
  const { user, config, isCompanyVerifiers, isVerifierUcropit, companyAllow } =
    useAuth()
  const { roleSelected } = config

  const hasFilter: boolean = useSelector(selectHasFilter())
  const canCreateActivity = validateRoleInUserConfigService(
    userPermissions.CREATE_ACTIVITY,
    roleSelected
  )

  const [isViewReady, setIsViewReady]: any = useState(false)
  const [isFabOptionsOpened, setIsFabOptionsOpened]: any = useState(false)
  const [snackbarText, setSnackbarText]: any = useState('')
  const [
    isSnackbarLicensesPendingVisible,
    setIsSnackbarLicensesPendingVisible,
  ]: any = useState(false)

  const { onPressCropMenu } = useCropMenu()
  const {
    isModalVisible: isModalValidateVisible,
    toggleModal: displayToggleModalValidate,
    closeModal: closeModalValidate,
  } = useModal()
  const {
    isModalVisible: isModalNoLongerLotsVisible,
    toggleModal: displayToggleModalNoLongerLots,
    closeModal: closeModalNoLongerLots,
  } = useModal()
  const { isLoading: isFetchCropLoading, crop }: any = useFetchCrop({
    cropId,
  })
  const { isLoading: isFetchPendingLicensesLoading, pendingLicenses }: any =
    useFetchPendingLicenses({
      cropId,
    })
  const {
    isLoading: isFetchActivitiesLoading,
    activities,
    nextPage,
  } = useFetchActivities({
    cropId,
  })
  const { fabActions } = useFab({
    cropId,
    areaUnit: crop?.areaUnit,
    surface: crop?.surface,
    cropType: crop?.cropType,
  })

  useEffect(() => {
    if (
      isFetchCropLoading ||
      isFetchPendingLicensesLoading ||
      isFetchActivitiesLoading
    ) {
      return
    }

    setIsViewReady(true)
  }, [
    isFetchCropLoading,
    isFetchPendingLicensesLoading,
    isFetchActivitiesLoading,
  ])

  useEffect(() => {
    if (!pendingLicenses.length || isCompanyVerifiers() || !isViewReady) {
      return
    }

    showSnackbarLicensesPending()
  }, [pendingLicenses, isViewReady])

  if (!isViewReady) {
    return (
      <View style={styles.indicatorContainer}>
        <ActivityIndicator color={primary500} />
      </View>
    )
  }

  const showSnackbarLicensesPending = () => {
    let licenseName

    if (pendingLicenses[0].name.length > 45) {
      licenseName = `${pendingLicenses[0].name.substring(0, 45)}...`
    } else {
      licenseName = pendingLicenses[0].name
    }

    let snackbarText = ''

    if (!pendingLicenses.find((element: any) => element.hasSubLicenses)) {
      if (pendingLicenses.length === 1) {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_34', {
          licenseName: licenseName,
        })
      } else {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_35')
      }
    } else {
      if (pendingLicenses.length === 1) {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_36', {
          licenseName: licenseName,
        })
      } else {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_37')
      }
    }

    setSnackbarText(snackbarText)

    setIsSnackbarLicensesPendingVisible(true)

    setTimeout(() => setIsSnackbarLicensesPendingVisible(false), 10000)
  }

  const onPressCropMenuOption = (option: any) => {
    if (option === CROP_MENU_OPTIONS.CROP_STORY) {
      enterToCropStory({
        date: new Date(),
        fromView: 'ACTIVITY_LIST',
        cropId: crop._id,
        fieldId: '',
        userId: user._id,
        userRol: config?.roleSelected?.equivalentRole,
        userIsAdmin: config?.isAdmin,
        userIsResponsible: config?.isResponsible,
        os: Platform.OS,
      })
    }

    onPressCropMenu(crop, option, canWrite)
  }

  const onPressFabButton = () => {
    if (isVerifierUcropit) {
      const drafts = activities
        .filter(
          ({ condition }: any) => ACTIVITY_CONDITIONS.DRAFT.key === condition
        )
        .map((element: any) => ({
          ...element,
          tag: element.activityTypeKey,
        }))

      const isError = searchErrorInAcVerification(crop, drafts)

      if (isError === MODAL_ERRORS.EXIST_VERIFICATION_DRAFT) {
        return displayToggleModalValidate()
      } else if (isError === MODAL_ERRORS.NO_LOTS_AVAILABLE) {
        return displayToggleModalNoLongerLots()
      }
      const activityType = activityTypes.find(
        (activityTypeFind) =>
          activityTypeFind.tag === activityTypesConstants.ACT_VERIFICATION.key
      )

      return navigation.navigate('ActivityCreate', {
        cropId,
        tag: activityTypesConstants.ACT_VERIFICATION.key,
        canSign: true,
        notDeepLink: true,
        activityTypeName: activityType?.label,
      })
    }

    setIsFabOptionsOpened(!isFabOptionsOpened)
  }

  /**
   * ON PRESS GO TO LICENSE DETAILS
   */
  const onPressGoToLicenseDetails = () => {
    if (pendingLicenses.length === 1) {
      navigation.navigate(RouterNames.LICENSES, {
        initialRouteName: RouterNames.LICENSES_DETAIL,
        cropId,
        licenseId: pendingLicenses[0]._id,
      })
    } else {
      navigation.navigate(RouterNames.LICENSES, {
        initialRouteName: RouterNames.LICENSES_LIST,
        cropId,
      })
    }
  }

  const onGoToActivity = ({
    canPlanning,
    isDirectAchievement,
    activitySignedByCurrentUser,
    currentUserIsActivitySigner,
    pendingSignsQuantity,
    condition,
    draftId,
    activityId,
    activityTypeKey,
  }: any) => {
    if (isDirectAchievementValidate(activityTypeKey)) {
      isDirectAchievement = false
    }

    if (ACTIVITY_CONDITIONS.DRAFT.key === condition) {
      const activityParams = {
        cropId,
        draftId,
        tag: activityTypeKey,
        canSign: !activitySignedByCurrentUser && currentUserIsActivitySigner,
        isDirectAchievement,
        notDeepLink: true,
      }

      return navigation.navigate('ActivityUpdate', activityParams)
    }

    if (ACTIVITY_CONDITIONS.PLANNED.key === condition) {
      return navigation.navigate('AchievementsActivityList', {
        cropId,
        mode: modes[activityTypeKey],
        tag: activityTypeKey,
        activityId,
      })
    }

    if (ACTIVITY_CONDITIONS.DONE.key === condition) {
      if (!canPlanning || isDirectAchievement) {
        return navigation.navigate('ActivityUpdate', {
          cropId,
          activityId,
          tag: activityTypeKey,
          canSign: !activitySignedByCurrentUser && currentUserIsActivitySigner,
          isSigned: Boolean(pendingSignsQuantity),
          isRealized: true,
          isDirectAchievement,
          notDeepLink: true,
        })
      }

      return navigation.navigate('AchievementsActivityList', {
        cropId,
        mode: modes[activityTypeKey],
        tag: activityTypeKey,
        activityId,
      })
    }

    if (ACTIVITY_CONDITIONS.FINISHED.key === condition) {
      if (!canPlanning || isDirectAchievement) {
        return navigation.navigate('ActivityFinished', {
          cropId,
          activityId,
          tag: activityTypeKey,
          isRealized: true,
          isFinished: true,
          isDirectAchievement,
          notDeepLink: true,
        })
      }

      return navigation.navigate('AchievementsActivityList', {
        cropId,
        mode: modes[activityTypeKey],
        tag: activityTypeKey,
        activityId,
      })
    }
  }

  const userType = String(
    crop?.members?.find((member: any) => member?.user?._id === user._id)?.type
  )

  const canWrite =
    userType !== userTypes.MARKETER.key && userType !== userTypes.PROVIDER.key

  const cropStatus =
    !crop?.company && crop?.status !== 'DRAFT'
      ? t('VIEWS').CROP.TEXT_29
      : crop?.status !== 'DRAFT'
      ? `${crop.pending.length} ${t('VIEWS').CROP.TEXT_30}`
      : crop?.status

  const isCropOffline =
    Boolean(
      crop?.members?.find(
        (member: any) =>
          member?.user?._id === user._id && member.isOfflineEnabled
      )
    ) || orderCropsBy?.key === ORDER_MENU_ENUM.ENABLED_OFFLINE

  const CropMenuComponent = Platform.OS === 'web' ? CropMenuWeb : CropMenuMobile
  const disabledCropMenuComponent =
    !companyAllow ||
    isFetchCropLoading ||
    isFetchPendingLicensesLoading ||
    isFetchActivitiesLoading

  const Header: FC<any> = (): ReactElement => {
    return (
      <View style={styles.headerContainer}>
        <CropDetails
          cropTypeKey={crop.cropType.key}
          cropSurface={crop.surface}
          areaUnit={crop.areaUnit}
          cropName={crop.name}
          companyName={crop.company.name}
          RightComponent={() => (
            <CropMenuComponent
              canWrite={canWrite}
              disabled={disabledCropMenuComponent}
              isCropOffline={isCropOffline}
              status={cropStatus}
              onPress={onPressCropMenuOption}
              showOptionDeleteCrop={false}
              showOptionEnableOrDisableOffline={false}
              hasLots={Boolean(
                crop?.establishments?.find((farm: { lots: string | any[] }) =>
                  Boolean(farm?.lots?.length)
                )
              )}
              userType={userType}
            />
          )}
        />
      </View>
    )
  }

  const ActivityCardRender: FC<any> = ({ item }): ReactElement => {
    return (
      <ActivityCard
        {...item}
        onGoToActivity={() => {
          onGoToActivity(item)
        }}
      />
    )
  }

  const Footer = () => {
    return isFetchActivitiesLoading ? (
      <ActivityIndicator
        color={primary500}
        containerStyle={styles.footerContainer}
      />
    ) : null
  }

  const EmptyStateComponent: FC<any> | any = (): ReactElement | boolean => {
    if (isFetchActivitiesLoading) {
      return false
    }

    return hasFilter ? <FilterEmptyState /> : <EmptyState />
  }

  return (
    <View style={styles.container}>
      <SnackbarLicensesPending
        visible={isSnackbarLicensesPendingVisible}
        snackbarText={snackbarText}
        onPressGoToLicenseDetails={onPressGoToLicenseDetails}
        licensesPending={pendingLicenses}
      />

      <FlatList
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        contentContainerStyle={styles.scrollContainer}
        data={activities}
        ListHeaderComponent={Header}
        renderItem={({ item }) => <ActivityCardRender item={item} />}
        keyExtractor={(element, index) => `ACTIVITY_CARD_INDEX_${index}`}
        ListEmptyComponent={EmptyStateComponent}
        onEndReached={!isFetchCropLoading ? nextPage : null}
        onEndReachedThreshold={0.5}
        refreshing={isFetchCropLoading}
        ListFooterComponent={() => <Footer />}
      />

      {!hasFilter && canCreateActivity && (
        <FabButton
          isFabOptionsOpened={isFabOptionsOpened}
          onPressFabButton={onPressFabButton}
          fabActions={fabActions}
        />
      )}

      <ValidateModal
        isModalVisible={isModalValidateVisible}
        closeModal={closeModalValidate}
      />

      <NoLongerFieldsModal
        isModalVisible={isModalNoLongerLotsVisible}
        closeModal={closeModalNoLongerLots}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: grayBackground,
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    paddingRight: 10,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingHorizontal: 24,
    paddingBottom: 50,
  },
  buttonFab: {
    backgroundColor: primary500,
  },
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerContainer: {
    marginVertical: 30,
  },
})

export default ActivityList
