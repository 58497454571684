import { makeRequest } from '@common/services'
import { useOfflineTypes } from '@offline/queries/types'
import { GlobalStateInterface } from '@store/interfaces'
import { setTypeAgreements } from '@store/slices'
import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

export const useTypeAgreements = () => {
  const dispatch = useDispatch()
  const { storeType, getTypes } = useOfflineTypes()
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )
  const fetchAgreementTypes = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'commons/agreements' })

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('agreements', data)
      }
    } else {
      data = await getTypes('agreements')
    }
    const typeAgreements = data.map((typeAgreement: any) => ({
      ...typeAgreement,
      label: typeAgreement.keyLabel,
      value: typeAgreement._id,
      key: typeAgreement.key,
      parentType: typeAgreement.parentTypes?.length
        ? typeAgreement.parentTypes[0].key
        : null,
      evidenceTypesAllowed:
        typeAgreement.evidenceTypesAllowed?.map(
          ({ evidenceConcepts, required }: any) => ({
            _id: evidenceConcepts?._id || null,
            code: evidenceConcepts?.code || null,
            required,
          })
        ) || [],
      multipleSelection: typeAgreement.multipleSelection,
      cropTypes: typeAgreement?.cropTypes ?? [],
    }))
    dispatch(setTypeAgreements(typeAgreements))
  }
  return {
    fetchAgreementTypes,
  }
}
