import { useSelector } from 'react-redux'

import { useOfflineQueue } from './queue'
import { activitiesStatus } from '@utils/constants'
import { alterTableAddColumns, cropColumns } from '../utils'
import {
  ITEMS_PER_PAGE,
  INITIAL_PAGE,
} from '@modules/crops/screens/CropList/v1/utils'
import { GlobalStateInterface } from '@store/interfaces'

const CREATE_TABLE_QUERY_CROPS =
  'CREATE TABLE IF NOT EXISTS crops (id integer primary key not null, data text, _id text, downloaded integer);'
const SELECT_QUERY =
  'SELECT * FROM crops WHERE downloaded = 1 GROUP BY _id ORDER BY updated_at DESC, created_at DESC;'
const SELECT_PAGINATED_QUERY =
  'SELECT * FROM crops WHERE downloaded = 1 AND (crop_identifier=? or member_identifier=?) GROUP BY _id ORDER BY updated_at DESC, created_at DESC limit ? offset ?;'
const DELETE_ALL_QUERY = 'DELETE FROM crops'
const DELETE_ONE_QUERY = 'DELETE FROM crops WHERE _id ='
const COUNT_QUERY = 'SELECT COUNT(id) AS count FROM crops'
const COUNT_QUERY_OFFLINE =
  'SELECT COUNT(id) AS count FROM crops WHERE downloaded = 1'
const INSERT_QUERY =
  'INSERT INTO crops (data, _id, downloaded, created_at, updated_at, crop_identifier, member_identifier) values (?, ?, 1, ?, ?, ?, ?)'

export const useOfflineCrops = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )
  const { activityTypes } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )

  const { storeQueueItem } = useOfflineQueue()

  const initOfflineCrop = async () => {
    if (!database) {
      return
    }

    const result = await database.execAsync(CREATE_TABLE_QUERY_CROPS)

    await alterTableAddColumns(database, 'crops', cropColumns)

    return
  }

  const deleteAllCropOffline = async () => {
    if (!database) {
      return
    }

    await deleteAllCrop()
  }

  const deleteAllCrop = async () => {
    await database.runAsync(DELETE_ALL_QUERY)

    return
  }

  const selectAllCrops = async () => {
    if (!database) {
      return []
    }

    const results = await database.getAllAsync(SELECT_QUERY)

    return results.map((element: any) => ({
      ...element,
      ...JSON.parse(element.data),
      data: null,
    }))
  }

  const getAllCrops = async ({
    page = INITIAL_PAGE,
    limit = ITEMS_PER_PAGE,
    identifier,
    companySelectedIdentifier,
  }: any) => {
    const offset = (page - 1) * limit

    const results = await database.getAllAsync(SELECT_PAGINATED_QUERY, [
      companySelectedIdentifier,
      identifier,
      limit,
      offset,
    ])

    return results.map((element: any) => ({
      ...element,
      ...JSON.parse(element.data),
      data: null,
    }))
  }

  const getPagedCropsOffline = async ({
    page = INITIAL_PAGE,
    limit = ITEMS_PER_PAGE,
    identifier,
    companySelectedIdentifier,
  }: any) => {
    if (!database) {
      return []
    }

    const items = await getAllCrops({
      page,
      limit,
      identifier,
      companySelectedIdentifier,
    })
    const totalItems = await getTotalCropsOffline()

    const response = {
      meta: {
        totalItems,
        itemsPerPage: limit,
        totalPages: limit > 0 ? Math.ceil(totalItems / limit) : 0,
        currentPage: page,
      },
      items,
    }

    return response
  }

  const addToCropStatus = async (
    idCrop: string,
    status: any,
    activity: any
  ) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * from individuals_crops where _id = '${idCrop}'`
    )

    let data = JSON.parse(result.data)

    const newStatus = [{ name: activitiesStatus[status] }]

    data = {
      ...data,
      [status]: [
        ...data[status],
        {
          ...activity,
          type: activityTypes.find((el) => el.value === activity.type),
          status: newStatus,
        },
      ],
    }

    data = JSON.stringify(data)

    await database.runAsync(
      'UPDATE individuals_crops SET data = ? where _id = ?',
      [data, idCrop]
    )

    return
  }

  const changeActivityStatusOrder = async (
    idCrop: string,
    idActivity: string,
    prevStatus: string,
    nextStatus: string
  ) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * from individuals_crops where _id = '${idCrop}'`
    )

    let data = JSON.parse(result.data)

    const tempActivity = data[prevStatus].find(
      (element: any) => element._id === idActivity
    )

    tempActivity.status = [{ name: activitiesStatus[nextStatus] }]

    data = {
      ...data,
      [prevStatus]: data[prevStatus].filter(
        (element: any) => element._id !== idActivity
      ),
      [nextStatus]: [...data[nextStatus], tempActivity],
    }

    data = JSON.stringify(data)

    await database.runAsync(
      'UPDATE individuals_crops SET data = ? where _id = ?',
      [data, idCrop]
    )

    return
  }

  const updateStatus = async (id: string, downloaded: boolean) => {
    if (!database) {
      return
    }

    await database.runAsync(
      `UPDATE crops SET downloaded = ${Number(downloaded)} where _id = '${id}';`
    )

    return
  }

  const syncCrops = async (
    data: any,
    forceOfflineSync: boolean,
    selectedCompanyIdentifier: string
  ) => {
    if (!database) {
      return
    }

    const result = await database.execAsync(COUNT_QUERY)

    if (data.length !== result.count || forceOfflineSync) {
      await database.runAsync(DELETE_ALL_QUERY)

      await Promise.all([
        data.map(async (crop: any) => {
          await database.runAsync(INSERT_QUERY, [
            JSON.stringify(crop),
            crop._id,
            crop?.createdAt || null,
            crop?.updatedAt || null,
            crop?.identifier,
            selectedCompanyIdentifier || null,
          ])
        }),
      ])
    }
  }

  const getTotalCropsOffline = async (): Promise<number> => {
    if (!database) {
      return 0
    }

    const result = await database.getFirstAsync(COUNT_QUERY_OFFLINE)

    return result.count
  }

  const storeOfflineCollaborator = async (
    id: string,
    values: any,
    params: any,
    type: string
  ) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * FROM individuals_crops where _id = '${id}'`
    )

    const parsed = JSON.parse(result.data)

    const data = {
      ...parsed,
      members: [...parsed.members, values],
    }

    await database.runAsync(
      `UPDATE individuals_crops SET data = '${JSON.stringify(data).replace(
        /[\/\(\)\']/g,
        '&quot;'
      )}' where _id = '${id}'`
    )

    await storeQueueItem(values, params, type)

    return
  }

  const findOneCrop = async () => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync('SELECT * from crops LIMIT 1;')

    if (!result) {
      return
    }

    return JSON.parse(result.data)
  }

  const findOneCropById = async (cropId: string) => {
    if (!database) {
      return
    }

    const result = await database.getFirstAsync(
      `SELECT * from crops WHERE _id = '${cropId}';`
    )

    return JSON.parse(result.data)
  }

  const insertCrop = async (crop: any, selectedCompanyIdentifier: string) => {
    if (!database) {
      return
    }

    const dataToInsert = parseDataToInsert(crop, selectedCompanyIdentifier)

    await insertCropToDB(dataToInsert)

    return
  }

  const parseDataToInsert = (crop: any, selectedCompanyIdentifier: string) => {
    const additionalData = JSON.stringify(crop)

    const dataToInsert = [
      additionalData,
      crop._id,
      crop?.createdAt ?? null,
      crop?.updatedAt ?? null,
      crop?.identifier,
      selectedCompanyIdentifier ?? null,
    ]

    return dataToInsert
  }

  const insertCropToDB = async (dataToInsert: any) => {
    await database.runAsync(INSERT_QUERY, dataToInsert)

    return
  }

  const deleteCrop = async (cropId: string) => {
    if (!database) {
      return
    }

    await deleteCropInDB(cropId)

    return
  }

  const deleteCropInDB = async (cropId: string) => {
    await database.runAsync(`${DELETE_ONE_QUERY} '${cropId}'`)

    return
  }

  return {
    initOfflineCrop,
    deleteAllCropOffline,
    syncCrops,
    selectAllCrops,
    getPagedCropsOffline,
    updateStatus,
    storeOfflineCollaborator,
    changeActivityStatusOrder,
    addToCropStatus,
    getTotalCropsOffline,
    findOneCrop,
    findOneCropById,
    insertCrop,
    deleteCrop,
  }
}
