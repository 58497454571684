export default {
  TEXT_1: 'UCROP.IT precisa permissões de localização',
  TEXT_2: 'A extenção permitida é .kmz',
  TEXT_3:
    'As extençóes permitidas seriam .doc,.docx,.pdf,.png,.jpg,.zip,.xlsx,.xls',
  TEXT_4: 'Extenção do arquivo não é permitida',
  TEXT_5: 'Aceito',
  TEXT_6: 'Agregar evidência',
  TEXT_7: 'Procurando georeferência...',
  TEXT_8: 'Por favor, não saia desta tela',
  TEXT_9:
    'O arquivo que você está tentando enviar não possui os dados de localização.',
  TEXT_10: 'Sem permissões de câmera',
  TEXT_11: 'Vá para as configurações e ative manualmente',
  TEXT_12: 'Cancelar',
  TEXT_13: 'Permitir',
  TEXT_14: 'Galeria',
  TEXT_15: 'Documentos',
  TEXT_16: 'Câmera',
  TEXT_17: 'Cancelar',
  TEXT_18: 'Tente enviar outro arquivo',
  TEXT_19:
    'O arquivo que você está tentando enviar não é um tipo de formato KMZ ou ele está danificado.',
  ERROR_SIZE: 'O arquivo excede o tamanho permitido, que é 100 MB.',
}
