import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IDatabaseSliceState {
  isReady: boolean
  database: any
}

const initialState = {
  isReady: false,
  database: undefined,
} as IDatabaseSliceState

export const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {
    setDatabase: (state, action: PayloadAction<IDatabaseSliceState>) => {
      state = {
        ...action.payload,
      }

      return state
    },
    resetValues: (state) => {
      state = initialState

      return state
    },
  },
})

export const { setDatabase, resetValues } = databaseSlice.actions

export const { reducer: databaseReducer } = databaseSlice
