import { useSelector } from 'react-redux'

import { GlobalStateInterface } from '@store/interfaces'

export const QUEUE_TYPES = {
  CREATE_COLLABORATOR: 'create_collaborator',
  COMMON_UPDATE: 'common_update',
  COMMON_CREATE: 'common_create',
}

export const CREATE_TABLE_QUERY_OFFLINE_QUEUE = `CREATE TABLE IF NOT EXISTS offline_queue (
      id integer primary key not null,
      data text,
      params text,
      time text,
      synced integer,
      type string
    );`

export const useOfflineQueue = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )

  const initOfflineQueue = async () => {
    if (!database) {
      return
    }

    await database.execAsync(CREATE_TABLE_QUERY_OFFLINE_QUEUE)

    return
  }

  const storeQueueItem = async (data: any, params: any, type: string) => {
    if (!database) {
      return
    }

    await database.runAsync(
      'INSERT INTO offline_queue (data, params, type, time, synced) values (?,?,?,?,0)',
      [JSON.stringify(data), JSON.stringify(params), type, Date.now()]
    )

    return
  }

  const showQueue = async () => {
    if (!database) {
      return []
    }

    const results = await database.getAllAsync(
      'SELECT * FROM offline_queue where synced = 0'
    )

    return results.map((element: any) => {
      return {
        ...element,
        type: element.type,
        time: Date.now(),
        data: JSON.parse(element.data),
        params: JSON.parse(element.params),
      }
    })
  }

  const changeSyncedFlag = async (id: string) => {
    if (!database) {
      return
    }

    await database.runAsync(
      `UPDATE offline_queue SET synced = 1 where id = '${id}'`
    )

    return
  }

  const dropAllQueue = async () => {
    if (!database) {
      return
    }

    await database.execAsync('DROP TABLE IF EXISTS offline_queue;')

    await initOfflineQueue()

    /**
     * TODO: Need to evaluate this code
     */
    /*
    await database.runAsync('DELETE FROM crops')
    await database.runAsync('DELETE FROM individuals_crops')
    */

    return
  }

  return {
    initOfflineQueue,
    storeQueueItem,
    showQueue,
    dropAllQueue,
    changeSyncedFlag,
  }
}
