const ALTER_TABLE_ADD_COLUMNS = 'ALTER TABLE table_name ADD COLUMN'

export const alterTableAddColumns = async (
  database: any,
  tableName: string,
  columns: any
) => {
  const tableColumns = await getTableColumns(database, 'crops')

  const diffColumns = diffBetweenArrays(mapColumns(columns), tableColumns)

  if (!diffColumns.length) return false

  const queries = buildQueryText(tableName, diffColumns)

  if (!queries.length) return false

  await Promise.all(
    queries.map(async (query: string) => {
      await database.execAsync(query)
    })
  )

  return
}

const buildQueryText = (tableName: string, columns: any) => {
  const ALTER_TABLE_QUERY = ALTER_TABLE_ADD_COLUMNS.replace(
    'table_name',
    tableName
  )

  return columns.map(
    (column: any) => `${ALTER_TABLE_QUERY} ${column.name} ${column.type};`
  )
}

const getTableColumns = async (database: any, tableName: string) => {
  const results = await database.getAllAsync(`PRAGMA table_info(${tableName})`)

  return results
}

const mapColumns = (columns: any) => {
  return columns.map((column: any) => {
    return {
      name: column.name,
      type: column.type,
    }
  })
}

const diffBetweenArrays = (itemsA: any, itemsB: any) => {
  return itemsA.filter(
    (item: any) => !itemsB.find((column: any) => column.name === item.name)
  )
}
