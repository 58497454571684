import { useSelector } from 'react-redux'

import { GlobalStateInterface } from '@store/interfaces'

export const CREATE_TABLE_QUERY_EVIDENCE_DRAFTS =
  'CREATE TABLE IF NOT EXISTS evidence_drafts (id integer primary key not null, achievementId text, fileName text, fileType text, fileUri text, date text, description text, location text, meta text, persisted bool, settings text);'
const DROP_QUERY = 'DROP TABLE IF EXISTS evidence_drafts;'
const INSERT_QUERY =
  'INSERT INTO evidence_drafts (achievementId, fileName, fileType, fileUri, date, description, location, meta, persisted, settings) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)'
const SELECT_QUERY = 'SELECT * FROM evidence_drafts'
const DELETE_QUERY = 'DELETE FROM evidence_drafts'

export const useOfflineEvidenceDrafts = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )

  const initOfflineEvidenceDrafts = async () => {
    if (!database) {
      return
    }

    await database.execAsync(CREATE_TABLE_QUERY_EVIDENCE_DRAFTS)

    return
  }

  const dropEvidenceDraftsTable = async () => {
    if (!database) {
      return
    }

    await database.execAsync(DROP_QUERY)

    return
  }

  const findEvidenceDrafts = async () => {
    if (!database) {
      return
    }

    const results = await database.getAllAsync(SELECT_QUERY)

    return results.map((element: any) => ({
      achievementId: element.achievementId,
      fileName: element.fileName,
      fileType: element.fileType,
      fileUri: element.fileUri,
      date: element.date,
      description: element.description,
      location: element.location ? JSON.parse(element.location) : '',
      meta: element.meta ? JSON.parse(element.meta) : '',
      persisted: element.persisted,
      settings: element.settings ? JSON.parse(element.settings) : '',
    }))
  }

  const saveEvidenceDraftsOffLine = async ({
    achievementId,
    fileName,
    fileType,
    fileUri,
    date,
    description,
    location,
    meta,
    persisted,
    settings,
  }: any) => {
    if (!database) {
      return
    }

    const dataToInsert = [
      achievementId,
      fileName,
      fileType,
      fileUri,
      date,
      description,
      location,
      meta,
      persisted,
      settings,
    ]

    await database.runAsync(INSERT_QUERY, dataToInsert)

    return
  }

  const deleteEvidenceDrafts = async () => {
    if (!database) {
      return
    }

    await database.runAsync(DELETE_QUERY)

    return
  }

  return {
    initOfflineEvidenceDrafts,
    dropEvidenceDraftsTable,
    findEvidenceDrafts,
    saveEvidenceDraftsOffLine,
    deleteEvidenceDrafts,
  }
}
