import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { Card } from 'react-native-paper'
import ButtonCustom from '@components/common/ButtonCustom'
import { SelectorContext } from '@contextState/selectors'
import { useOfflineCommon } from '../offline/queries/common'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'
import { sumByProp } from '@utils'
import { formatSurface, truncateSurface } from '@common/utils'
import { useSelector } from 'react-redux'
import InputCheckbox from '@components/inputs/InputCheckbox'

function LotsSelectorScreen({ route, navigation }) {
  const { doRequest } = useNetwork()
  const {
    setLotSelector,
    lotSelector,
    setSelectorTotalSurface,
    setLotSelectorData,
  } = useContext(SelectorContext)

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [establishments, setEstablishments] = useState([])
  const [lotSelected, setLotSelected] = useState([])
  const [isActivity, setIsActivity] = useState(false)
  const [selected, setSelected] = useState([])
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { showIndividuals } = useOfflineCommon()
  const { t } = useContext(LanguageContext)

  const fetchCrop = useCallback(async () => {
    if (isConnected) {
      let url = `${route.params.type}/${route.params.id}`

      if (route.params.type === 'crops') {
        url = `${url}/establishments`
      }

      const response = await doRequest({
        method: 'GET',
        url,
      })

      if (route.params.type === 'activities') {
        setIsActivity(true)
        setEstablishments(response.data.lots)
      } else {
        setEstablishments(response.data)
      }
    } else {
      const cropResponse = await showIndividuals(
        route.params.type,
        route.params.id
      )
      if (route.params.type === 'activities') {
        setIsActivity(true)
      }

      setEstablishments(cropResponse.establishments)
    }
  }, [])

  useEffect(() => {
    setSelected(lotSelector)
  }, [])

  useEffect(() => {
    fetchCrop()
  }, [isConnected])

  function handleCheck(id, element) {
    const found = selected.find((el) => el === id)
    if (!found) {
      setSelected([...selected, id])
      setLotSelected([...lotSelected, element])
      setLotSelectorData([...lotSelected, element])
    } else {
      setSelected(selected.filter((el) => el !== id))
      setLotSelected(lotSelected.filter((el) => el._id !== element._id))
      setLotSelectorData(lotSelected.filter((el) => el._id !== element._id))
    }
  }

  function handleSelect() {
    if (isSubmitted) {
      return
    }

    setIsSubmitted(true)

    setLotSelector(selected)

    const lots = establishments
      .flatMap((item) => item.data ?? item.lots)
      .filter((item) => selected.includes(item._id))

    if (route.params.callback) {
      route.params.callback(lots)

      return
    }

    setSelectorTotalSurface(truncateSurface(sumByProp(lots, 'surface')))
    navigation.goBack()
  }

  function renderLotsInCrops(lots) {
    return (
      lots &&
      lots.map((lot, key) => {
        return (
          <React.Fragment key={key}>
            {(lot?.data || lot.lots).map((el, key) => (
              <Card key={key} style={styles.card}>
                <Card.Title
                  left={(props) => (
                    <InputCheckbox
                      checked={Boolean(
                        selected.find((selection) => selection === el._id)
                      )}
                      disabled={route.params.status === 'readOnly'}
                      onPress={() => handleCheck(el._id, el)}
                    />
                  )}
                  title={el.name}
                  subtitle={`${formatSurface(el.surface, el.areaUnit)} ${
                    lot.tag
                  }`}
                />
              </Card>
            ))}
          </React.Fragment>
        )
      })
    )
  }

  function renderLotsInActivities(lots) {
    return (
      lots &&
      lots.map((lot, key) => {
        return (
          <React.Fragment key={key}>
            <Card key={key} style={styles.card}>
              <Card.Title
                left={(props) => (
                  <InputCheckbox
                    checked={Boolean(
                      selected.find((selection) => selection === lot._id)
                    )}
                    disabled={route.params.status === 'readOnly'}
                    onPress={() => handleCheck(lot._id, lot)}
                  />
                )}
                title={lot.name}
                subtitle={`${truncateSurface(lot.surface)} ${
                  lot?.areaUnit ?? t('VIEWS').LOTS_SELECTOR.TEXT_1
                }`}
              />
            </Card>
          </React.Fragment>
        )
      })
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        {isActivity
          ? renderLotsInActivities(establishments)
          : renderLotsInCrops(establishments)}
      </ScrollView>
      {route.params.status !== 'readOnly' && (
        <View style={styles.footer}>
          <ButtonCustom onPress={handleSelect} disabled={!selected.length}>
            {t('VIEWS').LOTS_SELECTOR.TEXT_2}
          </ButtonCustom>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
  },
  container: {
    flex: 1,
    padding: 16,
  },
  scroll: {
    flex: 1,
  },
  section: {
    padding: '0',
  },
})

export default LotsSelectorScreen
