import { useSelector } from 'react-redux'

import useNetwork from '@utils/network'
import { GlobalStateInterface } from '@store/interfaces'

const CREATE_QUERY = `CREATE TABLE IF NOT EXISTS subactivity_types (id integer primary key not null, _id text, data text);`
const DROP_QUERY = 'DROP TABLE IF EXISTS subactivity_types;'
const INSERT_QUERY = 'INSERT INTO subactivity_types ( _id, data) values (?, ?);'

export const useOfflineSubTypeActivities = () => {
  const { database } = useSelector(
    (state: GlobalStateInterface) => state.databaseReducer
  )
  const { doRequest } = useNetwork()

  const initOfflineSubTypeActivities = async () => {
    if (!database) {
      return
    }

    await createSubTypeActivityTable()
  }

  const createSubTypeActivityTable = async () => {
    if (!database) {
      return
    }

    await database.execAsync(CREATE_QUERY)

    return
  }

  const dropSubTypeActivityTable = async () => {
    if (!database) {
      return
    }

    await database.execAsync(DROP_QUERY)

    return
  }

  const syncSubTypeActivities = async () => {
    if (!database) {
      return
    }

    const response = await doRequest({
      method: 'GET',
      url: 'activities/subType',
      displayAlert: false,
    })

    const data = response.data

    for (const element of data) {
      const dataToInsert = [element._id, JSON.stringify(element)]

      await database.runAsync(INSERT_QUERY, dataToInsert)
    }
  }

  const getOfflineSubTypeActivities = async () => {
    if (!database) {
      return []
    }

    const results = await database.getAllAsync(
      `SELECT * from subactivity_types;`
    )

    return results.map((element: any) => JSON.parse(element.data))
  }

  async function resetSubTypeActivities() {
    if (!database) {
      return
    }

    await database.runAsync('DELETE from subactivity_types')

    return
  }

  return {
    initOfflineSubTypeActivities,
    syncSubTypeActivities,
    dropSubTypeActivityTable,
    getOfflineSubTypeActivities,
    resetSubTypeActivities,
  }
}
