import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  activityTypes: [],
  subTypeActivities: [],
}

export const activityTypesSlice = createSlice({
  name: 'activityTypes',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {func} state.onMessage
     *
     * @param {object} action
     * @property {object} action.payload
     * @property {string} action.payload.url
     * @property {func} action.payload.onMessage
     */
    setActivityTypes: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        activityTypes: action.payload,
      }
      return state
    },
    setSubtypesActivities: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        subTypeActivities: action.payload,
      }
      return state
    },
    resetActivityTypes: (state) => {
      state = initialState
      return state
    },
  },
})

export const { setActivityTypes, resetActivityTypes, setSubtypesActivities } =
  activityTypesSlice.actions

export const { reducer: activityTypesReducer } = activityTypesSlice
