import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { makeRequest } from '@common/services'
import { useOfflineTypes } from '@offline/queries/types'
import { GlobalStateInterface } from '@store/interfaces'
import {
  setActivityTypes,
  setSubtypesActivities,
} from '@store/slices/activityType.slice'
import { useOfflineSubTypeActivities } from '@offline/queries/subTypeActivities'

export const useActivityTypes = () => {
  const dispatch = useDispatch()
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )

  const { storeType, getTypes } = useOfflineTypes()

  const { syncSubTypeActivities, getOfflineSubTypeActivities } =
    useOfflineSubTypeActivities()

  const fetchActivityTypes = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'commons/activities' })

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('activities', data)
      }
    } else {
      data = await getTypes('activities')
    }

    const activityTypes = data.map((activityType: any) => ({
      ...activityType,
      label: activityType.keyLabel,
      value: activityType._id,
      tag: activityType.tag,
      canPlanning: activityType?.canPlanning,
    }))

    dispatch(setActivityTypes(activityTypes))
  }

  const fetchSubtypeActivities = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'activities/subType' })

      data = response.data

      if (Platform.OS !== 'web') {
        syncSubTypeActivities()
      }
    } else {
      data = await getOfflineSubTypeActivities()
    }

    const subtypesActivities = data.map((subtype: any) => ({
      label: subtype.codeLabel,
      activityType: subtype.activityType,
      value: subtype._id,
      codeLabel: subtype.codeLabel,
      parents: subtype.parents,
      key: subtype.key,
    }))

    dispatch(setSubtypesActivities(subtypesActivities))
  }

  return {
    fetchActivityTypes,
    fetchSubtypeActivities,
  }
}
