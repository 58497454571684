import React, { useState, useContext, useEffect } from 'react'
import { Platform, Pressable, Alert, Linking } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import * as Location from 'expo-location'
import * as DocumentPicker from 'expo-document-picker'
import * as MediaLibrary from 'expo-media-library'
import mime from 'mime'
import EXIF from 'exif-js'
import { useActionSheet } from '@expo/react-native-action-sheet'
import UploadWeb from '../common/UploadWeb'
import ModalLoading from '../common/ModalLoading'
import LoadingAnimation from '@assets/animations/lottie/loading'
import { VALID_DOCUMENTS, VALID_FORMATS_FILES } from '@utils/constants'
import { LanguageContext } from '@contextState/language'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import useModal from '@hooks/useModal'
import { promiseWithTimeout } from '@modules/common/utils'
import { MB_SIZE_100 } from '@constants/files'

const InputUpload = ({
  style,
  children,
  accept,
  showLocation,
  setFieldValue,
  nameFile = 'file',
  nameLocation = 'location',
  isPictureOnlyPicture,
  isDocumentOnly,
  cancelOnPress,
  onSelectFile,
  disabled,
  autoOpen,
  showAlert = true,
  testID = undefined,
}) => {
  const { t } = useContext(LanguageContext)

  const [loading, setLoading] = useState(false)
  const [isAlreadyHavePermissions, setIsAlreadyHavePermissions] =
    useState(false)

  const { showActionSheetWithOptions } = useActionSheet()
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const [textError, setTextError] = useState('')

  useEffect(() => {
    if (Platform.OS === 'web') {
      return
    }

    getPermissionMediaLibrary()
  }, [])

  useEffect(() => {
    if (!autoOpen || Platform.OS === 'web' || !isAlreadyHavePermissions) {
      return
    }

    _chooseMultimedia()
  }, [isAlreadyHavePermissions, autoOpen])

  const getPermissionMediaLibrary = async () => {
    const { granted } = await MediaLibrary.getPermissionsAsync()

    if (!granted) {
      const { granted } = await MediaLibrary.requestPermissionsAsync()

      if (!granted) {
        userRejectPermission()

        return
      }
    }

    await getPermissionCamera()
  }

  const getPermissionCamera = async () => {
    const { granted } = await ImagePicker.getCameraPermissionsAsync()

    if (!granted) {
      const { granted } = await ImagePicker.requestCameraPermissionsAsync()

      if (!granted) {
        userRejectPermission()

        return
      }
    }

    await getPermissionLocation()
  }

  const getPermissionLocation = async () => {
    const { granted } = await Location.getForegroundPermissionsAsync()

    if (!granted) {
      const { granted } = await Location.requestForegroundPermissionsAsync()

      if (!granted) {
        userRejectPermission()

        return
      }
    }

    setIsAlreadyHavePermissions(true)
  }

  const userRejectPermission = () => {
    Alert.alert(
      t('UTILS').PERMISSIONS.TEXT_1,
      t('UTILS').PERMISSIONS.TEXT_2,
      [
        {
          text: t('UTILS').PERMISSIONS.TEXT_3,
          onPress: () => cancelOnPress(),
        },
        {
          text: t('UTILS').PERMISSIONS.TEXT_4,
          onPress: () => Linking.openSettings(),
        },
      ],
      {
        cancelable: false,
      }
    )
  }

  const _setResult = async (result, type = 'photo') => {
    if (result.canceled) {
      return
    }

    const assetSelected = result?.assets?.length ? result.assets[0] : result

    let newUri

    if (type === 'document' && Platform.OS === 'android') {
      newUri = assetSelected.uri
    } else {
      newUri = 'file:///' + assetSelected.uri.split('file:/').join('')
    }

    const file = {
      uri: newUri,
      name: assetSelected.name || assetSelected.uri.split('/').pop(),
      type: mime.getType(newUri),
    }

    if (setFieldValue) {
      setFieldValue(nameFile, file)
    }

    if (onSelectFile) {
      onSelectFile(file)
    }
  }

  const _pickGallery = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        exif: true,
        quality: 1,
        mediaType: 'photo',
        cameraType: 'back',
        maxWidth: 8000,
        maxHeight: 8000,
      })

      if (showLocation) {
        setLoading(true)

        const [assetSelected] = result.assets

        const gpsLocation = await getLatLongExifDataInMobile(assetSelected)

        if (!gpsLocation?.latitude || !gpsLocation?.longitude) {
          alert(t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_9)
        }

        if (setFieldValue) {
          setFieldValue(nameLocation, gpsLocation || {})
          setFieldValue('isValidateLot', true)
        }

        setLoading(false)
      }

      _setResult(result)
    } catch (error) {
      console.warn(error.message)
    }
  }

  const _pickCamera = async () => {
    try {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        exif: true,
        quality: 1,
      })

      if (showLocation) {
        setLoading(true)

        const response = await getCurrentPositionAsyncWithRetry()

        const location = {
          latitude: response?.coords?.latitude,
          longitude: response?.coords?.longitude,
        }

        if (!location?.latitude || !location?.longitude) {
          alert(t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_1)
        }

        if (setFieldValue) {
          setFieldValue('isValidateLot', true)
          setFieldValue(nameLocation, location)
        }

        setLoading(false)
      }

      _setResult(result)
    } catch (error) {
      if (error.message === 'User rejected permissions') {
        Alert.alert(
          t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_10,
          t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_11,
          [
            {
              text: t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_12,
              onPress: () => console.warn('cancel'),
            },
            {
              text: t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_13,
              onPress: () => Linking.openURL('app-settings:'),
            },
          ],
          { cancelable: false }
        )
      }
      console.warn('error', error.message)
    }
  }

  const _pickDocument = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: '*/*',
        copyToCacheDirectory: true,
      })
      const getType = mime.getType(result.assets[0].uri)
      let message = null

      const typeMach =
        getType.match(
          accept === '.kmz' ? VALID_FORMATS_FILES : VALID_DOCUMENTS
        ) != null

      if (!typeMach) {
        message =
          accept === '.kmz'
            ? t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_2
            : t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_3
      } else {
        message =
          result.assets[0].fileSize > MB_SIZE_100
            ? t('COMPONENTS.INPUTS.INPUT_FILE_UPLOAD.ERROR_SIZE')
            : message
      }

      if (message && showAlert) {
        Alert.alert(
          t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_4,
          message,
          [
            {
              text: t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_5,
              onPress: () => {
                console.warn('Aceptar')
              },
            },
          ],
          { cancelable: false }
        )
        return
      }

      _setResult(result, 'document')
    } catch (error) {
      console.error('error pick document', error)
    }
  }

  const getCurrentPositionAsyncWithRetry = async () => {
    let position = null

    let attempts = 0

    while (!position && attempts !== 5) {
      try {
        position = await promiseWithTimeout(
          Location.getCurrentPositionAsync({
            accuracy: Location.Accuracy.Balanced,
          }),
          2000 * (attempts + 1)
        )
      } catch (error) {
        console.error(error.message)
      }

      attempts++
    }

    return position
  }

  const getLatLongExifDataInMobile = async ({ assetId }) => {
    if (!assetId) {
      return {}
    }

    const assetInfo = await MediaLibrary.getAssetInfoAsync(assetId)

    return assetInfo?.location || {}
  }

  const getLatLongExifData = (metaData) => {
    const {
      GPSLongitude: exifLong,
      GPSLongitudeRef: exifLongRef,
      GPSLatitude: exifLat,
      GPSLatitudeRef: exifLatRef,
    } = metaData
    let latitude, longitude

    if (!exifLong || !exifLongRef || !exifLat || !exifLatRef) return {}

    if (exifLatRef == 'S') {
      latitude = exifLat[0] * -1 + (exifLat[1] * -60 + exifLat[2] * -1) / 3600
    } else {
      latitude = exifLat[0] + (exifLat[1] * 60 + exifLat[2]) / 3600
    }

    if (exifLongRef == 'W') {
      longitude =
        exifLong[0] * -1 + (exifLong[1] * -60 + exifLong[2] * -1) / 3600
    } else {
      longitude = exifLong[0] + (exifLong[1] * 60 + exifLong[2]) / 3600
    }

    return latitude && longitude ? { latitude, longitude } : {}
  }

  const handleFile = async (values) => {
    if (setFieldValue) {
      setFieldValue(nameFile, values.file)
    }

    if (showLocation) {
      EXIF.getData(values.file, function () {
        const metaData = EXIF.getAllTags(this)
        const gpsLocation = getLatLongExifData(metaData)
        if (!gpsLocation.latitude || !gpsLocation.longitude) {
          alert(t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_9)
        }

        if (setFieldValue) {
          setFieldValue(nameLocation, gpsLocation)
          setFieldValue('isValidateLot', true)
        }
      })
    } else {
      if (setFieldValue) {
        setFieldValue(nameLocation, {})
      }
    }

    if (onSelectFile) {
      onSelectFile(values.file)
    }
  }

  const _chooseMultimedia = async () => {
    if (isDocumentOnly) {
      return _pickDocument()
    }

    const options = [
      isPictureOnlyPicture
        ? t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_14
        : t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_15,
      t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_16,
      t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_17,
    ]

    const cancelButtonIndex = 3

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          if (isPictureOnlyPicture) {
            _pickGallery()
          } else {
            _pickDocument()
          }
        } else if (buttonIndex === 1) {
          _pickCamera()
        }
      }
    )
  }

  const toggleModalError = (error) => {
    setTextError(error)
    toggleModal()
  }

  return (
    <>
      {loading ? (
        <ModalLoading
          isLoading={true}
          handleClose={() => setLoading(false)}
          animation={LoadingAnimation}
          animationStyle={{
            width: 150,
            height: 150,
          }}
          title={t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_6}
          loadingLabels={[
            t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_7,
            t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_8,
          ]}
        />
      ) : Platform.OS !== 'web' ? (
        <Pressable
          testID={testID}
          style={style}
          onPress={() => !disabled && _chooseMultimedia()}
        >
          {children}
        </Pressable>
      ) : (
        <UploadWeb
          handleFile={handleFile}
          accept={accept}
          style={style}
          disabled={disabled}
          autoOpen={autoOpen}
          toggleModalError={toggleModalError}
          testID={testID}
          t={t}
        >
          {children}
        </UploadWeb>
      )}
      <ModalConfirm
        visible={isModalVisible}
        onClose={closeModal}
        onConfirm={closeModal}
        title={t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_7}
        contentText={
          accept === '.kmz'
            ? t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_5
            : textError
        }
        confirmText={t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_8}
      />
    </>
  )
}

export default InputUpload
