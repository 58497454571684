import { useEffect } from 'react'
import { Platform } from 'react-native'
import { useDispatch } from 'react-redux'

import { setDatabase } from '@store/slices'

const DATABASE_NAME = 'db.offlinedata'

export const useDatabase = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (Platform.OS === 'web') {
      return
    }

    const SQLite = require('expo-sqlite')

    const database = SQLite.openDatabaseSync(DATABASE_NAME)

    dispatch(
      setDatabase({
        isReady: true,
        database,
      })
    )
  }, [])
}
